// assessmentAttitudeItems
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import { Table, Tooltip, Space, DatePicker, Form, Button, Input, Typography, Modal, Flex } from 'antd';
import { getMannerAssessment } from '../../api/assessment';
import store from "../../redux/store";
import ACTION_OF from "../../redux/action";
import { saveScores, EditableCell, departmentFilters } from './assessmentHelpers';

const AttitudeAssessment = () => {
    const [assessmentData, setAssessmentData] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState(null);
    const { id, token } = useSelector(state => state.session);
    const [scores, setScores] = useState({});
    const [comments, setComments] = useState({});
    const [temporaryStorageScores, setTemporaryStorageScores] = useState({});
    const [form] = Form.useForm();
    const [selectMonth, setSelectMonth] = useState(dayjs().date() <= 20 ? dayjs().subtract(1, 'month').format('YYYY-MM') : dayjs().format('YYYY-MM'));
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isAssessmentCompleted, setIsAssessmentCompleted] = useState(false);
    const [currentRecord, setCurrentRecord] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getMannerAssessment(selectMonth, { staffId: id, token }).toPromise();
                if (response !== null) {
                    setAssessmentData(response);
                    setSelectedStaff(response[0]?.staffDto?.id);
                    const initialScores = {};
                    const initialComments = {};
                    response.forEach(item => {
                        if (item["assessmentAttitudeItems"]) {
                            item["assessmentAttitudeItems"].forEach(result => {
                                initialScores[`${item.staffDto.id}-${result.id}`] = 10;
                            });
                        }
                    });
                    response.forEach(item => {
                        if (item.assessmentResults) {
                            item.assessmentResults.forEach(result => {
                                initialScores[`${item.staffDto.id}-${result.assessmentItemId}`] = result.assessmentItemScore;
                            });
                        }
                    });
                    response.forEach(item => {
                        if (item.assessmentComments && typeof item.assessmentComments === 'object') {
                            initialComments[item.staffDto.id] = item.assessmentComments.comment;
                        }
                    });
                    setIsAssessmentCompleted(false);
                    setScores(initialScores);
                    setComments(initialComments);
                    setTemporaryStorageScores(initialScores);
                } else {
                    setIsAssessmentCompleted(true);
                    store.dispatch(ACTION_OF.ALERT.INFO('已完成這月份考核'));
                }
            } catch (error) {
                store.dispatch(ACTION_OF.ALERT.ERROR(error));
            }
        } fetchData();
    }, [selectMonth]);

    const setMonth = (date) => {
        setSelectMonth(date.format('YYYY-MM'));
        console.log("Selected date:", selectMonth); // 记录格式化日期
    };

    const selectedStaffData = useMemo(() => {
        return assessmentData.find(item => item.staffDto.id === selectedStaff);
    }, [assessmentData, selectedStaff]);

    const handleScoreChange = (staffId, itemId, score) => {
        if (score !== undefined) {
            setScores(prevScores => ({
                ...prevScores,
                [`${staffId}-${itemId}`]: score
            }));
        }
    };

    const columns = [
        {
            title: '考核項目',
            dataIndex: 'content',
            key: 'content',
        },
        {
            title: '考核分數',
            dataIndex: 'score',
            key: 'score',
            render: (text, record) => {
                const currentScore = scores[`${selectedStaffData.staffDto.id}-${record.id}`];
                console.log(currentScore);
                console.log(10 - currentScore);
                
                return (
                    <Input
                        type="number"
                        value={currentScore !== undefined ? parseFloat((10.0 - currentScore).toFixed(10)) : ''}
                        min={0.5} max={10} step={0.1}
                        style={{ width: '100px' }}
                        onChange={e => {
                            let value = e.target.value.trim(); // 移除前后的空格
                            if (value === '') {
                                // 处理空值情况
                                handleScoreChange(selectedStaffData.staffDto.id, record.id, 0);
                            } else {
                                value = parseFloat(value);
                                if (!isNaN(value) && value >= 0 && value <= 10) {
                                    value = parseFloat(value.toFixed(1));
                                    // 这里是关键：在回传前只显示用户输入的值，不立即计算
                                    const adjustedValue = parseFloat(10-value);
                                    handleScoreChange(selectedStaffData.staffDto.id, record.id, parseFloat(adjustedValue.toFixed(1)));
                                }
                            }
                        }}
                    />
                );
            },
        }
    ]

    const assessmentColumns = Array.from({ length: 5 }, (_, index) => ({
        title: `${index + 1}`,
        dataIndex: `${index + 1}`,
        key: `${index + 1}`,
        width: 15,
        render: (text, record) => {
            const { score, content } = record[`${index + 1}`]; // Destructure score and content
            return (
                <Tooltip placement="topLeft" title={content}>
                    {score !== undefined ? score : "N/A"} {/* Render score as string */}
                </Tooltip>
            );
        },
    }));



    const tableColumns = [
        {
            title: '名字',
            dataIndex: 'staffDto.name',
            key: 'name',
            width: 20,
            fixed: 'left',
            render: (text, record) => `${record.staffDto.name} (${record.staffDto.nickName})`,
        }, {
            title: '部門',
            dataIndex: 'staffDto.department',
            key: 'department',
            width: 20,
            filters: departmentFilters(assessmentData),
            onFilter: (value, record) => record.staffDto.department === value,
            render: (text, record) => record.staffDto.department,
        }, {
            title: '考核项目',
            dataIndex: 'assessment',
            key: 'assessment',
            children: assessmentColumns,
        },
        {
            title: '编辑',
            key: 'edit',
            width: 20,
            fixed: 'right',
            render: (text, record) => (
                <Button onClick={() => handleEdit(record)}>编辑</Button>
            ),
        }
    ];

    const handleEdit = (record) => {
        setSelectedStaff(record.staffDto.id);
        setCurrentRecord(record);
        form.setFieldsValue(record);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        form.validateFields().then(values => {
            dataSource.map(item => {
                if (item.key === currentRecord.key) {
                    return { ...item, ...values };
                }
                return item;
            });
            saveScores(assessmentData, scores, comments, selectMonth, "assessmentAttitudeItems", id, token, false);
            setIsModalVisible(false);
        }).catch(info => {
            console.log('Validate Failed:', info);
        });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setScores(temporaryStorageScores);
    };

    const processAssessmentResults = (items, staffId) => {
        let index = 0;
        return items ? items.reduce((acc, result) => {
            index += 1;
            acc[`${index}`] = {
                score: scores[`${staffId}-${result.id}`] || '',
                content: result.content,
            };
            return acc;
        }, {}) : {};
    };

    const dataSource = assessmentData.map(item => {
        let assessmentResults = {};
        assessmentResults = processAssessmentResults(item.assessmentAttitudeItems, item.staffDto.id);


        // 添加默认值 “暂未评分”
        const assessmentResultsWithDefault = {};
        for (let i = 1; i <= 10; i++) {
            assessmentResultsWithDefault[`${i}`] = assessmentResults[`${i}`] || 10;
        }

        return {
            key: item.staffDto.id,
            staffDto: item.staffDto,
            ...assessmentResultsWithDefault,
            assessmentContents: Object.fromEntries(
                Object.entries(assessmentResults).map(([index, result]) => [index, result.content])
            ),
        };
    });

    const handleSubmit = () => {
        form.validateFields().then(() => {
            saveScores(assessmentData, scores, comments, selectMonth, "assessmentAttitudeItems", id, token, true);
            setIsAssessmentCompleted(true);
        });
    };

    return (
        <>
            <Flex gap="small" wrap="wrap" style={{ marginBottom: '20px', marginTop: '20px' }}>
                <br />
                <DatePicker picker={'month'} defaultValue={dayjs(selectMonth, 'YYYY-MM')} format={'YYYY/MM'} onChange={setMonth} allowClear={false} />
            </Flex>
            {selectedStaffData && (
                <div key={selectedStaffData}>
                    {isAssessmentCompleted ? (
                        <Typography.Text>已完成這月份考核</Typography.Text>
                    ) : (
                        <Form onFinish={handleSubmit} form={form} >
                            <div style={{ margin: '0 50px' }}>
                                <Table
                                    dataSource={dataSource}
                                    columns={tableColumns}
                                /></div>
                            <Modal title={
                                <div style={{ textAlign: 'center', width: '100%' }}>
                                    {`${selectedStaffData.staffDto.name}-${selectedStaffData.staffDto.nickName} (${selectedStaffData.staffDto.department}-${selectedStaffData.staffDto.jobTitle})`}
                                </div>
                            }
                                visible={isModalVisible}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                okText="暂存"
                                width={"50%"}
                            >
                                <div style={{ margin: '0 10px' }}>
                                    <Table
                                        dataSource={selectedStaffData["assessmentAttitudeItems"].map(item => ({ ...item, key: item.id }))}
                                        columns={columns}
                                        pagination={false}
                                        width={"100%"}
                                        style={{ display: 'flex', justifyContent: 'center', width: 'auto' }}
                                    /></div>
                            </Modal>
                            <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                                <Space direction="vertical" style={{ width: '80%' }}>
                                </Space>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                <Button type='primary' htmlType='submit'>全部送出</Button>
                            </div>
                        </Form>
                    )}
                </div>
            )}
        </>
    );

};
export default AttitudeAssessment;
