import {pipe} from "ramda";
import {ofType} from "redux-observable";
import TYPE_OF from "../action/types";
import {pluck, switchMap,mergeMap} from 'rxjs/operators';
import ACTION_OF from "../action";
import {of} from "rxjs";
import catchApiError from "../../http/catchApiError";
import {updateShopMenu, addShop,getAllTeatimeMenu,getTeatimeFormInfos} from "../../api/teatimeEdit";


const searchShopMenuEpic = pipe(
    ofType(TYPE_OF.TEATIME.SEARCH_SHOP_MENU.REQUEST),
    pluck('payload'),
    switchMap(payload => {
        return getAllTeatimeMenu(payload).pipe(
            switchMap((response) => of(ACTION_OF.TEATIME.SEARCH_SHOP_MENU.SUCCESS({list : response}))),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR(message)))
        )
    }),
);

const updateShopMenuEpic = pipe(
    ofType(TYPE_OF.TEATIME.UPDATE_SHOP_MENU.REQUEST),
    pluck('payload'),
    mergeMap(payload => {
        const { getValues, staffId, token } = payload; 
        return updateShopMenu(getValues, { "staffId":staffId, token }).pipe( 
            mergeMap(() => {
                return of(
                    ACTION_OF.TEATIME.SEARCH_SHOP_MENU.REQUEST( { "staffId":staffId, token })
                );
            }),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR( message)))
        )
    }),
);

const addShopEpic = pipe(
    ofType(TYPE_OF.TEATIME.ADD_SHOP.REQUEST),
    pluck('payload'),
    mergeMap(payload => {
        const { getValues, staffId, token } = payload; 
        return addShop(getValues, { "staffId":staffId, token }).pipe( 
            mergeMap(() => {
                return of(
                    ACTION_OF.TEATIME.SEARCH_SHOP_MENU.REQUEST( { "staffId":staffId, token })
                );
            }),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR( message)))
        )
    }),
);

const searchTeatimeEpic = pipe(
    ofType(TYPE_OF.TEATIME.SEARCH_TEATIME.REQUEST),
    pluck('payload'),
    switchMap(payload => {
        return getTeatimeFormInfos(payload).pipe(
            switchMap((response) => of(ACTION_OF.TEATIME.SEARCH_TEATIME.SUCCESS({list : response}))),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR(message)))
        )
    }),
);

export default {
    searchShopMenuEpic,
    updateShopMenuEpic,
    addShopEpic,
    searchTeatimeEpic
};
