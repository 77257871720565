import {catchError} from "rxjs/operators";
import {of} from "rxjs";
import ACTION_OF from "../redux/action";

const catchApiError = callback =>
    catchError((selector) => {
        const {response} = selector;
        if (response.status === 401) {
            return of(
                ACTION_OF.SESSION.INVALID(),
                ACTION_OF.ALERT.ERROR('EXPIRED_TOKEN'),
            );
        } else if (response.status === 404) {
            return of(ACTION_OF.ALERT.ERROR('NOT_FOUND'));
        } else {
            if (response) {
                return callback(response.message);
            }
            return callback('UNKNOWN_ERROR');
        }
    });


export default catchApiError;