import {  Form,  Modal, Input } from 'antd';
import React, { useState, useEffect } from "react";
import { hadWished, makeWish } from "../api/session";
import { useSelector } from "react-redux";

function Wishingwell() {
    const [form] = Form.useForm();
    const { id, token } = useSelector(state => state.session);
    const [visible, setVisible] = useState(false);
    const { TextArea } = Input;

    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await hadWished({ staffId: id, token }).toPromise();
            setVisible(!response)
        } catch (error) {
            console.error("Error ", error);
        }
        };
        fetchData();
    }, [id,token]);

    const handleSubmit = () => {
        form.validateFields().then(async (record) => {
            // console.log(record);
            try {
                const requestBody =record.wishingwell
                await makeWish(requestBody, { staffId: id, token }).toPromise();
                    setVisible(false);
            } catch (error) {
                console.error("Error ", error);
            }
        });
    };

    const handleCancel = () => {

        setVisible(false);
    }

    return (
    <div className="footer-image">
        <Modal
            title="說出你的願望吧~"
            open={visible}
            onOk={() => handleSubmit(form.getFieldsValue())}
            onCancel={handleCancel}
            // footer={null} // 隐藏底部按钮
            centered
        >
            <Form
                form={form}
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="許願內容"
                    name="wishingwell"
                    rules={[{ required: true, message: '請輸入願望!' }]}
                >
                    <TextArea style={{ width: '100%' }} autoSize={{ minRows: 5, maxRows: 5 }} />
                </Form.Item>

                {/* <Form.Item 
                    style={{ textAlign: "right" }}
                    >
                        <Button type='primary' htmlType='submit'> 許願 </Button>
                    </Form.Item> */}
            </Form>
        </Modal>
    </div>

    );

}


export default Wishingwell;