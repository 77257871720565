import {handleActions} from 'redux-actions';
import TYPE_OF from "../action/types";
import produce from 'immer';

const initialState = {
    list: [], // 添加这个属性
};

const staff = handleActions(
    {
        [TYPE_OF.STAFF.SEARCH_STAFF.SUCCESS]: produce((draft, {payload}) => {
            draft.list = payload.list;
            // console.log(draft.list);
        }),
        [TYPE_OF.STAFF.SEARCH_STAFF.FAILURE]: produce((draft) => {
            draft.list = [];
        }),
    },
    initialState
);
export default staff;