import {createAction} from 'redux-actions';
import TYPE_OF from './types';

function createActionByType(types) {
    return Object.entries(types).reduce((accumulator, [key, value]) => {
        if (typeof value === 'object') {
            accumulator[key] = createActionByType(value);
            return accumulator;
        }
        accumulator[key] = createAction(value);
        return accumulator;
    }, {});
}


const ACTION_OF = createActionByType(TYPE_OF);
export default ACTION_OF;