import { pipe } from "ramda";
import { ofType } from "redux-observable";
import TYPE_OF from "../action/types";
import { pluck, switchMap, mergeMap } from 'rxjs/operators';
import ACTION_OF from "../action";
import { of, forkJoin } from "rxjs";
import catchApiError from "../../http/catchApiError";
import { getAssessmentItem, addAssessmentItem, getMannerAssessment, getAllJobTitleAssessmentItem, updateAssessmentForm, addAssessmentForm } from "../../api/assessment";


const searchAssessmentListEpic = pipe(
    ofType(TYPE_OF.ASSESSMENT.SEARCH_ASSESSMENT.REQUEST),
    pluck('payload'),
    switchMap(payload => {
        console.log(payload);
        return getAssessmentItem(payload).pipe(
            switchMap((response) => of(ACTION_OF.ASSESSMENT.SEARCH_ASSESSMENT.SUCCESS({ list: response }))),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR(message)))
        )
    }),
);

const updateAssessmentListEpic = pipe(
    ofType(TYPE_OF.ASSESSMENT.UPDATE_ASSESSMENT.REQUEST),
    pluck('payload'),
    mergeMap(payload => {
        const { getValues, staffId, token } = payload;
        return addAssessmentItem(getValues, { "staffId": staffId, token }).pipe(
            mergeMap(() => {
                return of(
                    ACTION_OF.ASSESSMENT.SEARCH_ASSESSMENT.REQUEST({ "staffId": staffId, token })
                );
            }),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR(message)))
        )
    }),
);

const attitudeAssessmentEpic = pipe(
    ofType(TYPE_OF.ASSESSMENT.SEARCH_ATTITUDE_ASSESSMENT.REQUEST),
    pluck('payload'),
    switchMap(payload => {
        return getMannerAssessment(payload).pipe(
            switchMap((response) => of(ACTION_OF.ASSESSMENT.SEARCH_ATTITUDE_ASSESSMENT.SUCCESS({ list: response }))),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR(message)))
        )
    }),
);

//职称ID像对应的考核ID
const allJobTitleAssessmentItemEpic = pipe(
    ofType(TYPE_OF.ASSESSMENT.SEARCH_ALL_JOB_ASSESSMENT.REQUEST),
    pluck('payload'),
    switchMap(payload => {
        return forkJoin({
            ability: getAllJobTitleAssessmentItem("工作能力", payload),
            attitude: getAllJobTitleAssessmentItem("工作態度", payload)
        }).pipe(
            switchMap((responses) => {
                return of(ACTION_OF.ASSESSMENT.SEARCH_ALL_JOB_ASSESSMENT.SUCCESS({
                    list: {
                        ability: responses.ability,
                        attitude: responses.attitude
                    }
                }));
            }),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR(message)))
        )
    }),
);

const updateJobAssessmentEpic = pipe(
    ofType(TYPE_OF.ASSESSMENT.UPDATE_JOB_ASSESSMENT.REQUEST),
    pluck('payload'),
    mergeMap(payload => {
        const { getValues, staffId, token } = payload;
        return updateAssessmentForm(getValues, { "staffId": staffId, token }).pipe(
            mergeMap(() => {
                return of(
                    ACTION_OF.ASSESSMENT.SEARCH_ALL_JOB_ASSESSMENT.REQUEST({ "staffId": staffId, token })
                );
            }),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR(message)))
        )
    }),
);

const addJobAssessmentEpic = pipe(
    ofType(TYPE_OF.ASSESSMENT.ADD_JOB_ASSESSMENT.REQUEST),
    pluck('payload'),
    mergeMap(payload => {
        const { getValues, staffId, token } = payload;
        return addAssessmentForm(getValues, { "staffId": staffId, token }).pipe(
            mergeMap(() => {
                return of(
                    ACTION_OF.ASSESSMENT.SEARCH_ALL_JOB_ASSESSMENT.REQUEST({ "staffId": staffId, token })
                );
            }),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR(message)))
        )
    }),
);

export default {
    searchAssessmentListEpic,
    updateAssessmentListEpic,
    attitudeAssessmentEpic,
    allJobTitleAssessmentItemEpic,
    updateJobAssessmentEpic,
    addJobAssessmentEpic
};
