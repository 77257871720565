// 下午茶菜单编辑
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Form, Select, Modal, Input, Button, Flex, DatePicker, Tree, Card, InputNumber, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import ACTION_OF from "../../redux/action";
import { getShop, createTeatime, deleteTeatimeForm } from "../../api/teatimeEdit";
import store from "../../redux/store";
import moment from "moment";


const TeatimeEdit = () => {
    const { id, token } = useSelector(state => state.session);
    const [visibleAddShop, setVisibleAddShop] = useState(false);
    const [visibleAddTeaTime, setVisibleAddTeaTime] = useState(false);
    const [renew, setRenew] = useState(false);
    const [shopForm] = Form.useForm();
    const [form2] = Form.useForm();
    const [shop, setShop] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const dispatch = useDispatch();
    const { list: list, teatimeList } = useSelector(state => ({
        list: state.teatime.list,
        teatimeList: state.teatime.teatimeList
    }));
    const [isAdding, setIsAdding] = useState(false);

    useEffect(() => {
        dispatch(ACTION_OF.TEATIME.SEARCH_SHOP_MENU.REQUEST({ staffId: id, token }));
        dispatch(ACTION_OF.TEATIME.SEARCH_TEATIME.REQUEST({ staffId: id, token }));
    }, [dispatch, id, token, renew]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseShop = await getShop({ staffId: id, token }).toPromise();
                setShop(responseShop);
            } catch (error) {
                store.dispatch(ACTION_OF.ALERT.ERROR(error));
            }
        };
        fetchData();
    }, [id, token]);

    const typeColors = {
        '食品': 'red',
        '飲品': 'green',
    };

    const columns = [
        {
            title: '店名',
            dataIndex: 'shopName',
            key: 'shopName',
        },
        {
            title: '類別',
            dataIndex: 'type',
            key: 'type',
            render: (type) => {
                const color = typeColors[type] || 'blue'; // 如果没有匹配到颜色，使用默认颜色
                return (
                    <Tag color={color} key={type}>
                        {type.toUpperCase()}
                    </Tag>
                );
            },
        },
    ];

    const teatimeTable = [
        {
            title: '店名',
            dataIndex: 'shopName',
            key: 'teatimeShopName',
        },
        {
            title: '歡樂下午茶日期',
            dataIndex: 'teatimeDate',
            key: 'teatimeDate',
        },
        {
            title: '收單截止時間',
            dataIndex: 'closeRegistration',
            key: 'closeRegistration',
        },
    ];
    //開啟添加店家視窗
    const handleAddNewSop = () => {
        setIsAdding(true);
        setVisibleAddShop(true);
        console.log("添加店家");
    }

    const mockData = Object.values(list.reduce((acc, current) => {
        if (!acc[current.shopName]) {
            acc[current.shopName] = { title: current.shopName, key: current.shopName, children: [] };
        }
        acc[current.shopName].children.push({
            key: current.id,
            title: current.item,
            price: current.price,
            type: current.type
        });
        return acc;
    }, {}));

    const handleTreeCheck = (checkedKeys, info) => {
        const itemList = checkedKeys
            .filter(key => typeof key === 'number')
            .map(id => ({ id }));

        const shopListIdsSet = new Set();

        itemList.forEach(item => {
            const foundItem = list.find(shop => shop.id === item.id);
            if (foundItem) {
                // 在 shop 数组中查找对应的 id
                const foundShop = shop.find(s => s.shopName === foundItem.shopName);
                if (foundShop) {
                    shopListIdsSet.add(foundShop.id);
                }
            }
        });
        const shopListIds = [...shopListIdsSet];
        setCheckedKeys({ shopList: shopListIds, itemList });
    };

    const addNewShop = () => {
        shopForm.validateFields().then(values => {
            if (isAdding) {
                const getValues = {
                    shopName: values.shopName,
                    teatimeMenuList: values.items.map(item => ({
                        shopName: values.shopName,
                        item: item.productName,
                        price: item.price,
                        type: item.type
                    }))
                }
                try {
                    const values = { getValues, staffId: id, token };
                    dispatch(ACTION_OF.TEATIME.ADD_SHOP.REQUEST(values));
                    console.log(getValues);
                } catch (error) {
                    console.error("Error ", error);
                }
            } else {
                const getValues =
                    values.items.map(item => ({
                        id: (item.key === "" ? "" : item.key),
                        shopName: values.shopName,
                        item: item.productName,
                        price: item.price,
                        type: item.type
                    }))
                try {
                    const values = { getValues, staffId: id, token };
                    dispatch(ACTION_OF.TEATIME.UPDATE_SHOP_MENU.REQUEST(values));
                    console.log(getValues);
                } catch (error) {
                    store.dispatch(ACTION_OF.ALERT.ERROR(error));
                }
            }

            setVisibleAddTeaTime(false);
            shopForm.resetFields();
        })
        setVisibleAddShop(false);
        setIsAdding(false);
    }
    //開啟建立下午茶視窗
    const handleAddTEaTimeMenu = () => {
        console.log("建立下午茶");
        setVisibleAddTeaTime(true);
        setIsAdding(true);
    }

    const addTeaTimeMenu = () => {
        form2.validateFields().then(async values => {
            console.log(values);
            const getValues = {
                teatimeDate: values.teatimeDate.format('YYYY-MM-DD'),
                closeRegistration: new Date(values.closeRegistration).toISOString().slice(0, 19),
                teatimeShopIds: checkedKeys.shopList,
                teatimeMenusIds: checkedKeys.itemList.map(item => item.id)
            };
            await createTeatime(getValues, { staffId: id, token }).toPromise();
            setVisibleAddTeaTime(false);
            form2.resetFields();
            store.dispatch(ACTION_OF.ALERT.INFO("下午茶建立成功"));
            setRenew(!renew);
        }).catch(error => {
            store.dispatch(ACTION_OF.ALERT.ERROR(error));
        });
    };

    const deleteTeatime = async () => {
        form2.validateFields().then(async values => {
            setIsAdding(false);
            console.log(values.teatimeDate.format('YYYY-MM-DD'));
            await deleteTeatimeForm(values.teatimeDate.format('YYYY-MM-DD'), { staffId: id, token }).toPromise();
            setVisibleAddTeaTime(false);
            store.dispatch(ACTION_OF.ALERT.INFO("已删除"));
            setRenew(!renew);
            form2.resetFields();
        }).catch(error => {
            store.dispatch(ACTION_OF.ALERT.ERROR(error));
        });
    };

    const hideModal = () => {
        setIsAdding(false);
        setVisibleAddShop(false);
        setVisibleAddTeaTime(false);
        setCheckedKeys([]);
        shopForm.resetFields();
        form2.resetFields();
    }

    const handleDoubleClick = (record) => {
        setIsAdding(false);
        setVisibleAddShop(true);
        const itemList = list.filter(item => item.shopName === record.shopName);
        console.log(itemList);
        shopForm.resetFields();
        shopForm.setFieldsValue({
            shopName: record.shopName,
            items: itemList.map(item => ({
                key: item.id,
                productName: item.item,
                type: item.type,
                price: item.price
            }))
        });
    };
    const teatimeDoubleClick = (record) => {
        setIsAdding(false);
        setVisibleAddTeaTime(true);
        console.log(record.teatimeDate);
        const itemList = teatimeList.filter(item => item.teatimeDate === record.teatimeDate);
        const checked = itemList[0].teatimeMenus.map(item => item.id);
        setCheckedKeys(checked);
        form2.setFieldsValue({
            teatimeDate: moment(record.teatimeDate),
            closeRegistration: moment(record.closeRegistration)
        });
    };

    const disabledDate = current => {
        const teatimeDate = form2.getFieldValue('teatimeDate');
        const today = moment().startOf('day'); // 获取今天的开始时间
        if (!teatimeDate) return true;
        return current && (current.isAfter(teatimeDate, 'day') || current.isBefore(today, 'day'));
    };

    return (
        <>
            <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                <Flex gap="small" wrap="wrap">
                    <Button type="primary" onClick={handleAddTEaTimeMenu}>建立下午茶</Button>
                    <Button type="primary" onClick={handleAddNewSop}>新增店家</Button>
                </Flex>
            </div>
            <Table
                title={() => '目前開放點餐的下午茶'}
                dataSource={teatimeList}
                columns={teatimeTable}
                rowKey={(record) => record.teatimeDate}
                onRow={(record) => ({
                    onDoubleClick: () => teatimeDoubleClick(record),
                })}
            />
            <Table
                dataSource={shop}
                columns={columns}
                rowKey={(record) => record.id}
                onRow={(record) => ({
                    onDoubleClick: () => handleDoubleClick(record),
                })}
            />
            <Modal
                title={isAdding ? "添加店家" : "修改店家品項"}
                open={visibleAddShop}
                onOk={addNewShop}
                onCancel={hideModal}
                okText="確認"
                cancelText="取消"
            >
                <Form form={shopForm} >
                    <Form.Item
                        label="輸入店名"
                        name="shopName"
                        rules={[{ required: true, message: '輸入店名!' }]}>
                        <Input disabled={!isAdding} />
                    </Form.Item>
                    <Form.List name="items">
                        {(fields, { add, remove }) => (
                            <div
                                style={{
                                    display: 'flex',
                                    rowGap: 16,
                                    flexDirection: 'column',
                                }}
                            >
                                {fields.map((field) => (
                                    <Card
                                        size="small"
                                        title={`品項 ${field.name + 1}`}
                                        key={field.key}
                                        extra={
                                            <CloseOutlined
                                                onClick={() => {
                                                    remove(field.name);
                                                }}
                                            />
                                        }
                                    >
                                        <Form.Item label="品名" name={[field.name, 'productName']}>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label="類別" name={[field.name, 'type']} initialValue="飲品">
                                            <Select
                                                defaultValue="飲品"
                                                style={{
                                                    width: 120,
                                                }}
                                                options={[
                                                    {
                                                        value: '飲品',
                                                        label: '飲品',
                                                    },
                                                    {
                                                        value: '食品',
                                                        label: '食品',
                                                    },
                                                    {
                                                        value: '附餐',
                                                        label: '附餐',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                        <Form.Item label="價錢" name={[field.name, 'price']} initialValue={50}>
                                            <InputNumber
                                                defaultValue={50}
                                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                            />
                                        </Form.Item>
                                    </Card>
                                ))}

                                <Button type="dashed" onClick={() => add()} block>
                                    + 新增品項
                                </Button>
                            </div>
                        )}
                    </Form.List>

                </Form>
            </Modal>

            <Modal
                title="建立下午茶"
                open={visibleAddTeaTime}
                onOk={addTeaTimeMenu}
                onCancel={hideModal}
                okButtonProps={isAdding ? "" : { style: { display: 'none' } }}
                cancelButtonProps={isAdding ? "" : { style: { display: 'none' } }}
                okText="確認"
                cancelText="取消"
            >
                <Form form={form2}>
                    <Form.Item
                        label="選擇下午茶日期"
                        name="teatimeDate"
                        rules={[{ required: true, message: '請選擇日期!' }]}
                    >
                        <DatePicker
                            disabled={!isAdding}
                            disabledDate={(current) => current && current < moment().startOf('day')} />
                    </Form.Item>
                    <Form.Item
                        label="選擇收單時間"
                        name="closeRegistration"
                        rules={[
                            { required: true, message: '請選擇日期!' },
                        ]}
                    >
                        <DatePicker
                            showTime
                            disabledDate={disabledDate}
                            disabled={!isAdding}
                        />
                    </Form.Item>
                    <Form.Item name="teatimeMenus">
                        {isAdding ? (
                            <Tree
                                checkable
                                treeData={mockData}
                                onCheck={handleTreeCheck}
                            />
                        ) : (
                            <Tree
                                checkable
                                disabled
                                treeData={mockData}
                                defaultExpandedKeys={checkedKeys}
                                checkedKeys={checkedKeys}
                            />
                        )}
                    </Form.Item>
                    {isAdding ? (null) : (
                        <Button type="primary" onClick={deleteTeatime}>删除</Button>)}
                </Form>
            </Modal>
        </>
    )
};

export default TeatimeEdit;
