import {createEpicMiddleware} from "redux-observable";
import {applyMiddleware, createStore } from "redux";
import rootReducer from "../reducer";
import {toastMiddleware} from "../middleware";
import rootEpic from "../epic";

const epicMiddleware = createEpicMiddleware();

const store = createStore (
    rootReducer,
    applyMiddleware(toastMiddleware, epicMiddleware)
);
epicMiddleware.run(rootEpic);

export default store;