// 許願池統計表
import React, { useState, useEffect } from 'react';
import {  useSelector } from "react-redux";
import { Table, Flex, DatePicker } from 'antd';
import { getWishingWellStatistics } from '../../api/statistics/WishingWellStatistics';
import dayjs from 'dayjs';
const WishingWellStatistics = () => {
    const { id, token } = useSelector(state => state.session);
    const [selectMonth, setSelectMonth] = useState(dayjs().format('YYYY-MM'))
    const [wishingwellList, setWishingwellList] = useState([]);

    const setMonth = (date) => {
        setSelectMonth(date.format('YYYY-MM'));
        console.log("Selected date:", selectMonth); // 记录格式化日期
    };
    const columns = [
        {
            title: '許願人',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '暱稱',
            dataIndex: 'nickName',
            key: 'nickName',
        },
        {
            title: '許願內容',
            dataIndex: 'wishContent',
            key: 'wishContent',
        },
    ];
    useEffect(() => {
        const headers = {
            'staffId': id,
            token,
        };
        const requestBody = selectMonth;
        const fetchData = async () => {
            try {
                const response = await getWishingWellStatistics(requestBody, headers).toPromise();
                console.log('response', response);
                setWishingwellList(response);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [id,token,selectMonth])

    return (
        <>
            <br></br>
            <Flex gap="small" wrap="wrap">
                <br />
                <DatePicker picker={'month'} defaultValue={dayjs()} format={'YYYY/MM'} onChange={setMonth}   allowClear={false}/>
            </Flex>
            <Table dataSource={wishingwellList} columns={columns} rowKey={(record) => record.id} />
        </>
    )
}

export default WishingWellStatistics;