import {handleActions} from 'redux-actions';
import TYPE_OF from "../action/types";
import produce from 'immer';

const initialState = {
    list: [],
    jobList: [],
};


const assessment = handleActions(
    {
        [TYPE_OF.ASSESSMENT.SEARCH_ASSESSMENT.SUCCESS]: produce((draft, {payload}) => {
            draft.list = payload.list;
        }),
        [TYPE_OF.ASSESSMENT.SEARCH_ASSESSMENT.FAILURE]: produce((draft) => {
            draft.list = [];
        }),
        [TYPE_OF.ASSESSMENT.SEARCH_ALL_JOB_ASSESSMENT.SUCCESS]: produce((draft, {payload}) => {
            draft.jobList = payload.list;
        }),
        [TYPE_OF.ASSESSMENT.SEARCH_ALL_JOB_ASSESSMENT.FAILURE]: produce((draft) => {
            draft.jobList = [];
        }),
    },
    initialState
);

export default assessment;