import { pluck, switchMap } from 'rxjs/operators';
import { ofType } from "redux-observable";
import { pipe } from 'ramda';
import TYPE_OF from "../action/types";
import { login, logout, loginByToken } from "../../api/session";
import ACTION_OF from "../action";
import { of } from "rxjs";
import * as http from "../../http";
import catchApiError from "../../http/catchApiError";
import store from "../store";



const loginEpic = pipe(
    ofType(TYPE_OF.SESSION.LOGIN.REQUEST),
    pluck('payload'),
    switchMap(payload => {
        return login(payload).pipe(
            switchMap((response) => {
                http.setHeaderToken(response.staff.token);
                store.dispatch(ACTION_OF.ALERT.INFO('登入成功'));
                return of(
                    ACTION_OF.SESSION.LOGIN.SUCCESS({
                        id: response.staff.id,
                        token: response.staff.token,
                        nickName: response.staff.nickName,
                        permissions: response.staff.permissions,
                        avatar: response.staff.avatar
                    })
                )
            }),
            catchApiError(message => {
                return of(
                    ACTION_OF.SESSION.LOGIN.FAILURE({ errorMessage: message }),
                    ACTION_OF.ALERT.ERROR(message))
            }
            )
        )
    }),
);
const loginOutEpic = pipe(
    ofType(TYPE_OF.SESSION.LOGOUT.REQUEST),
    pluck('payload'),
    switchMap(payload => {
        return logout(payload).pipe(
            switchMap(() => {
                store.dispatch(ACTION_OF.ALERT.INFO('登出成功'));
                return of(
                    ACTION_OF.SESSION.LOGOUT.SUCCESS({})
                )
            }),
            catchApiError(message => {
                return of(
                    ACTION_OF.SESSION.LOGOUT.FAILURE({ errorMessage: message }),
                    ACTION_OF.ALERT.ERROR(message))
            }
            )
        )
    }),
);

const loginByTokenEpic = pipe(
    ofType(TYPE_OF.SESSION.LOGIN_BY_TOKEN.REQUEST),
    pluck('payload'),
    switchMap(payload => {
        return loginByToken(payload).pipe(
            switchMap((response) => {
                return of(
                    ACTION_OF.SESSION.LOGIN.SUCCESS({
                        id: response.staffDto.id,
                        token: response.staffDto.token,
                        nickName: response.staffDto.nickName,
                        permissions: response.staffDto.permissions,
                        avatar: (response.staffDto.avatar == null ? "AndroidOutlined.png" : response.staffDto.avatar)
                    })
                )
            }),
            catchApiError(message => {
                return of(
                    ACTION_OF.SESSION.LOGIN.FAILURE({ errorMessage: message }),
                    ACTION_OF.ALERT.ERROR(message))
            }
            )
        )
    }),
);

export default {
    loginEpic,
    loginByTokenEpic,
    loginOutEpic,
};