import Get from "./Get"
import Post from "./Post"
import Delete from "./Delete"

export const API_HOST = process.env.REACT_APP_API_HOST;

export const buildUrl = (path, parameters) => {
    let qs = "";
    for (const key in parameters) {
        if (parameters.hasOwnProperty(key)) {
            const value = parameters[key];
            if (value !== undefined && value !== null && value !== '') {
                qs += encodeURIComponent(key) + "=" + encodeURIComponent(value) + "&";
            }
        }
    }
    if (qs.length > 0) {
        qs = qs.substring(0, qs.length - 1); //chop off last "&"
        path = path + "?" + qs;
    }

    return path;
};
export const HEADER_TOKEN = {};

export const setHeaderToken = token => {
    HEADER_TOKEN.Authorization = `${token}`;
}
export const removeHeaderToken = () => {
    delete HEADER_TOKEN.Authorization;
};
export default {Get, Post, Delete};