import React, { useState, useEffect } from "react";
import { Space, Form, Table, Modal, Select, Button, Radio, Typography } from "antd";
import { getTeatimeRegisterForm, makeOrder, getOrder } from "../api/teatime/index";
import { useSelector } from "react-redux";

function MenuItem({ shop, action, type }) {
    return (
        <Radio.Group onChange={action}  >
            <Space direction="vertical">
                {shop.teatimeMenus.map(teatimeMenu => (
                    teatimeMenu.type === type && (
                        <Radio
                            key={teatimeMenu.id}
                            value={teatimeMenu.id}
                        >
                            {teatimeMenu.item}
                        </Radio>
                    )
                ))}
                {shop.teatimeMenus.some(menu => menu.type === type) ?
                    <Radio key="none" value={0}>無</Radio> : null}
            </Space>
        </Radio.Group>
    )
}
function TeaTimeMenu() {
    const [haveNewTeatime, setHaveNewTeatime] = useState(true);
    const [formVisible, setFormVisible] = useState(false);
    const [renew, setRenew] = useState(false);
    const [form] = Form.useForm();
    const { id, token } = useSelector(state => state.session);
    const [drink, setDrink] = useState(0);
    const [food, setFood] = useState(0);
    const [sidedish, setSidedish] = useState(0);
    const [teatimeDataSource, setTeatimeDataSource] = useState([]);
    const [order, setOrder] = useState([]);
    const [ice, setIce] = useState('正常');
    const [sugar, setSugar] = useState('正常');

    const iceOptions = [
        {
            value: '正常',
            label: '正常',
        }, {
            value: '少冰',
            label: '少冰',
        }, {
            value: '微冰',
            label: '微冰',
        }, {
            value: '去冰',
            label: '去冰',
        }
    ];
    const sugarOptions = [
        {
            value: '全糖',
            label: '全糖',
        }, {
            value: '七分',
            label: '七分',
        }, {
            value: '五分',
            label: '五分',
        }, {
            value: '三分',
            label: '三分',
        }, {
            value: '無糖',
            label: '無糖',
        }
    ];
    const columns = [
        { title: '餐點', dataIndex: 'foodName', key: 'foodName' },
        { title: '附餐', dataIndex: 'sideDishName', key: 'sideDishName' },
        { title: '飲料', dataIndex: 'drinkName', key: 'drinkName' },
        { title: '甜度', dataIndex: 'sugar', key: 'sugar' },
        { title: '冰塊', dataIndex: 'ice', key: 'ice' },
    ];
    useEffect(() => {
        try {
            const response = getTeatimeRegisterForm({ staffId: id, token })
                .subscribe(response => {
                    console.log(response);
                    setTeatimeDataSource(response);
                    if (!response || response.length === 0) {
                        setHaveNewTeatime(false);
                    }
                });
        } catch (error) {
            console.error("Error ", error);
        }
        try {
            const orderResponse = getOrder({ staffId: id, token })
                .subscribe(orderResponse => {
                    console.log(orderResponse);
                    if (orderResponse[0] !== null && orderResponse.length !== 0) {
                        setFormVisible(false);
                        setOrder(orderResponse);
                        console.log("有訂單");
                    } else {
                        console.log("沒有訂單");
                    }
                });
        } catch (error) {
            console.error("Error ", error);
        }
    }, [id,token,renew])
    const handleSubmit = async () => {
        console.log("送出訂單");
        const requestBody = {
            "staffId": id,
            "foodId": food,
            "sideDishId": sidedish,
            "drinkId": drink,
            "sugar": sugar,
            "ice": ice,
            "teatimeDate": teatimeDataSource[0].teatimeDate
        }
        try {
            await makeOrder(requestBody, { staffId: id, token }).toPromise();
        } catch (error) {
            console.error("Error ", error);
        }
        console.log(requestBody);
        setRenew(!renew);
        setFormVisible(!formVisible);
    }
    const handleCancel = () => {
        setFormVisible(false);
        console.log("取消");
    }

    const foodChange = (e) => {
        setFood(e.target.value)
        console.log('radio checked', e.target.value);
    }
    const drinkChange = (e) => {
        setDrink(e.target.value)
        console.log('radio checked', e.target.value);
    }
    const sidedishChange = (e) => {
        setSidedish(e.target.value)
        console.log('radio checked', e.target.value);
    }
    const handleIceChange = (value) => {
        setIce(value);
        console.log(`selected ${value}`);
    };
    const handleSugarChange = (value) => {
        setSugar(value);
        console.log(`selected ${value}`);
    };
    return <>
        {!haveNewTeatime ? <Typography.Title level={4}>( ´•̥̥̥ω•̥̥̥` )暫無下午茶</Typography.Title> :
         <div>
            <Typography.Title level={4}> (๑´ڡ`๑)下午茶~~~｡:.ﾟヽ(*´∀`)ﾉﾟ.:｡/ </Typography.Title>
            <Button type="primary" onClick={() => setFormVisible(true)}>點餐</Button>
            <Table dataSource={order} columns={columns} rowKey={(record) => record.id} />
        </div>}
        <Modal
            title="請點單"
            open={formVisible}
            onOk={handleSubmit}
            onCancel={handleCancel}
            okText="確認"
            cancelText="取消">
            <Form form={form}>
                {teatimeDataSource && teatimeDataSource.length > 0 && teatimeDataSource.map(shop => (
                    <Form.Item
                        label={shop.shopName}
                        name={shop.shopName}
                        key={shop.shopName}
                    >
                        {shop.teatimeMenus.some(menu => menu.type === '食品') ?
                            <Typography.Title level={5}>餐點</Typography.Title> : null}
                        <MenuItem
                            shop={shop}
                            action={foodChange}
                            type={'食品'}
                        />
                        {shop.teatimeMenus.some(menu => menu.type === '附餐') ?
                            <Typography.Title level={5}>附餐</Typography.Title> : null}
                        <MenuItem
                            shop={shop}
                            action={sidedishChange}
                            type={'附餐'}
                        />
                        {shop.teatimeMenus.some(menu => menu.type === '飲品') ?
                            <Typography.Title level={5}>飲料</Typography.Title> : null}
                        <MenuItem
                            shop={shop}
                            action={drinkChange}
                            type={'飲品'}
                        />
                        {shop.teatimeMenus.some(menu => menu.type === '飲品') ?
                            <Space wrap>
                                冰塊
                                <Select
                                    defaultValue="正常"
                                    style={{
                                        width: 80,
                                    }}
                                    onChange={handleIceChange}
                                    options={iceOptions}
                                />
                                甜度
                                <Select
                                    defaultValue="正常"
                                    style={{
                                        width: 80,
                                    }}
                                    onChange={handleSugarChange}
                                    options={sugarOptions}
                                />
                            </Space> : null}
                    </Form.Item>
                ))}
            </Form>
        </Modal>
    </>
}

export default TeaTimeMenu;