import { pipe } from "ramda";
import { ofType } from "redux-observable";
import TYPE_OF from "../action/types";
import { pluck, switchMap, mergeMap } from 'rxjs/operators';
import ACTION_OF from "../action";
import { of } from "rxjs";
import catchApiError from "../../http/catchApiError";
import { getAllStaff, saveOrUpdateStaff } from "../../api/staff";

const searchStaffEpic = pipe(
    ofType(TYPE_OF.STAFF.SEARCH_STAFF.REQUEST),
    pluck('payload'),
    switchMap(payload => {
        return getAllStaff(payload).pipe(
            switchMap((response) => of(ACTION_OF.STAFF.SEARCH_STAFF.SUCCESS({ list: response }))),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR(message)))
        )
    }),
);

const updateStaffEpic = pipe(
    ofType(TYPE_OF.STAFF.UPDATE_STAFF.REQUEST),
    pluck('payload'),
    mergeMap(payload => {
        const { getValues, staffId, token } = payload; // 解构出所需的值
        return saveOrUpdateStaff(getValues, { "staffId": staffId, token }).pipe( // 传递到 API 调用中
            mergeMap(() => {
                return of(
                    ACTION_OF.STAFF.SEARCH_STAFF.REQUEST({ "staffId": staffId, token })
                );
            }),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR(message)))
        )
    }),
);

export default {
    searchStaffEpic,
    updateStaffEpic
};
