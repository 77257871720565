import {handleActions} from 'redux-actions';
import TYPE_OF from "../action/types";
import produce from 'immer';

const initialState = {
    list: [], // 添加这个属性
};

const jobTitle = handleActions(
    {
        [TYPE_OF.JOB_TITLE.SEARCH_JOB_TITLE.SUCCESS]: produce((draft, {payload}) => {
            draft.list = payload.list;
        }),
        [TYPE_OF.JOB_TITLE.SEARCH_JOB_TITLE.FAILURE]: produce((draft) => {
            draft.list = [];
        }),
    },
    initialState
);
export default jobTitle;