import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Tooltip, Space, DatePicker, Form, Button, Input, Typography, Modal, Flex } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import ACTION_OF from "../../redux/action";
import dayjs from 'dayjs';
import { getKpiAssessment } from '../../api/assessment';
import store from "../../redux/store";
import { saveScores, EditableCell, departmentFilters } from './assessmentHelpers';

const KpiAssessment = () => {
  const [assessmentData, setAssessmentData] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const { id, token } = useSelector(state => state.session);
  const [scores, setScores] = useState({});
  const [comments, setComments] = useState({});
  const [form] = Form.useForm();
  const [sumScores, setSumScores] = useState({});
  const [inputStatus, setInputStatus] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [selectMonth, setSelectMonth] = useState(dayjs().date() <= 20 ? dayjs().subtract(1, 'month').format('YYYY-MM') : dayjs().format('YYYY-MM'));
  const [isAssessmentCompleted, setIsAssessmentCompleted] = useState(false);
  const [temporaryStorageScores, setTemporaryStorageScores] = useState({});
  const [temporaryStorageComments, setTemporaryStorageComments] = useState({});
  const [editingKey, setEditingKey] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getKpiAssessment(selectMonth, { staffId: id, token }).toPromise();
        if (response !== null) {
          setAssessmentData(response);
          setSelectedStaff(response[0]?.staffDto?.id);
          const initialScores = {};
          const initialComments = {};
          response.forEach(item => {
            if (item["assessmentAbilityItems"]) {
              item["assessmentAbilityItems"].forEach(result => {
                initialScores[`${item.staffDto.id}-${result.id}`] = 5;
              });
            }
          });
          response.forEach(item => {
            if (item.assessmentResults) {
              item.assessmentResults.forEach(result => {
                initialScores[`${item.staffDto.id}-${result.assessmentItemId}`] = result.assessmentItemScore;
              });
            }
          });
          response.forEach(item => {
            if (item.assessmentComments && typeof item.assessmentComments === 'object') {
              initialComments[item.staffDto.id] = item.assessmentComments.comment;
            }
          });
          setIsAssessmentCompleted(false);
          setScores(initialScores);
          setComments(initialComments);
          setTemporaryStorageScores(initialScores);
          setTemporaryStorageComments(initialComments);

        } else {
          setIsAssessmentCompleted(true);
          store.dispatch(ACTION_OF.ALERT.INFO('已完成這月份考核'));
        }
      } catch (error) {
        store.dispatch(ACTION_OF.ALERT.ERROR(error));
      }
    }
    fetchData();
  }, [selectMonth]);


  const isEditing = (record) => record.key === editingKey;

  const edit = (key) => {
    setEditingKey(key);

    form.setFieldsValue({
      totalScore: sumScores[key],
      comments: comments[key],
    });
  };

  const cancel = () => {
    setEditingKey('');
  };

  const setMonth = (date) => {
    setSelectMonth(date.format('YYYY-MM'));
    console.log("Selected date:", selectMonth); // 记录格式化日期
  };

  const calculateTotalScore = (id, totalScore) => {
    const formattedTotalScore = parseFloat(totalScore.toFixed(1));
    const averageScore = Math.floor((formattedTotalScore / 10) * 10) / 10;
    let remainingScore = Math.round((totalScore - averageScore * 10) * 10) / 10;
    let finalScore = Array(10).fill(averageScore);

    for (let i = 0; i < remainingScore * 10; i++) {
      finalScore[i % 10] += 0.1;
    }
    finalScore = finalScore.map(finalScore => Math.round(finalScore * 10) / 10);

    let i = 0;
    setScores(prevScores => {
      const updatedScores = { ...prevScores };
      const keysToUpdate = [];

      for (const key in prevScores) {
        const prefix = key.split('-')[0];
        if (prefix === id.toString()) {
          keysToUpdate.push(key);
          updatedScores[key] = finalScore[i].toFixed(1);
          i++;
        }
      }
      return updatedScores;
    });
  };

  useEffect(() => {
    let initialTotalScores = {};
    assessmentData.forEach(item => {
      let totalScore = 0;
      item.assessmentAbilityItems.forEach(abilityItem => {
        const abilityItemId = abilityItem.id;
        for (const key in scores) {
          const [prefix, suffix] = key.split('-');
          if (prefix === item.staffDto.id.toString() && parseInt(suffix) === abilityItemId) {
            totalScore += parseFloat(scores[key]);
          }
        }
      });
      initialTotalScores[item.staffDto.id] = parseFloat(totalScore.toFixed(1));
    });
    setSumScores(initialTotalScores);
  }, [scores]);

  const handleCommentChange = (staffId, value) => {
    setComments(prevComments => ({
      ...prevComments,
      [staffId]: value
    }));
  };

  const assessmentColumns = Array.from({ length: 10 }, (_, index) => ({
    title: `${index + 1}`,
    dataIndex: `${index + 1}`,
    key: `${index + 1}`,
    width: 15,
    render: (text, record) => {
      const content = record.assessmentContents[index + 1];
      return (
        <Tooltip placement="topLeft" title={content}>
          {text}
        </Tooltip>
      );
    },
  }));


  const tableColumns = [
    {
      title: '名字',
      dataIndex: 'staffDto.name',
      key: 'name',
      width: 20,
      fixed: 'left',
      render: (text, record) => `${record.staffDto.name} (${record.staffDto.nickName})`,
    }, {
      title: '部門',
      dataIndex: 'staffDto.department',
      key: 'department',
      width: 20,
      filters: departmentFilters(assessmentData),
      onFilter: (value, record) => record.staffDto.department === value,
      render: (text, record) => record.staffDto.department,
    }, {
      title: '總分',
      dataIndex: 'totalScore',
      key: 'totalScore',
      width: 20,
      editable: true,
      render: (text, record) => sumScores[record.staffDto.id],
    }, {
      title: '評語',
      dataIndex: 'comments',
      key: 'comments',
      width: 50,
      editable: true,
      ellipsis: {
        showTitle: false,
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    }, {
      title: '考核项目',
      dataIndex: 'assessment',
      key: 'assessment',
      children: assessmentColumns,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 25,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              保存
            </Button>
            <Button onClick={cancel}>取消</Button>
          </span>
        ) : (
          <span>
            <Button disabled={editingKey !== ''} onClick={() => edit(record.key)}>
              <EditOutlined />
            </Button>
            <Button disabled={editingKey !== ''} onClick={() => handleEdit(record)}>编辑</Button>
          </span>
        );
      },
    },
  ];

  const mergedColumns = tableColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'totalScore' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const save = async (key) => {
    try {
      const values = await form.validateFields();

      if (parseFloat(values.totalScore) > 50) {
        store.dispatch(ACTION_OF.ALERT.ERROR('總分不能超過50分'));
        return;
      }
      if (values.comments && values.comments.length > 2000) {
        store.dispatch(ACTION_OF.ALERT.ERROR('評語不能超過2000字'));
        return;
      }
      calculateTotalScore(key, parseFloat(values.totalScore.toFixed(1)));
      handleCommentChange(key, values.comments);

      setScores(prevScores => {
        setComments(prevComments => {
          // 保存更新后的 scores 和 comments
          saveScores(assessmentData, prevScores, prevComments, selectMonth, "assessmentAbilityItems", id, token, false);
          setTemporaryStorageScores(prevScores);
          setTemporaryStorageComments(prevComments);
          return prevComments;
        });
        return prevScores;
      });

      setEditingKey('');
    } catch (errInfo) {
      store.dispatch(ACTION_OF.ALERT.ERROR(errInfo));
    }
  };

  const columns = [
    {
      title: '考核項目',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: '考核分數',
      dataIndex: 'score',
      key: 'score',
      render: (text, record) => (
        <Input
          type="number"
          value={scores[`${selectedStaffData.staffDto.id}-${record.id}`] !== undefined ? scores[`${selectedStaffData.staffDto.id}-${record.id}`] : ''}
          min={0.5} max={8} step={0.1}
          style={{ width: '100px' }}
          onChange={e => {
            let value = e.target.value.trim();
            if (value === '') {
              handleScoreChange(selectedStaffData.staffDto.id, record.id, 0);
            } else {
              value = parseFloat(value);
              if (!isNaN(value) && value >= 0.5 && value <= 5) {
                value = parseFloat(value.toFixed(1));
                handleScoreChange(selectedStaffData.staffDto.id, record.id, value);
              }
            }
          }}
        />
      ),
    }
  ];

  const handleScoreChange = (staffId, itemId, score) => {
    if (score !== undefined) {
      setScores(prevScores => ({
        ...prevScores,
        [`${staffId}-${itemId}`]: score
      }));
    }
  };

  const processAssessmentResults = (items, staffId) => {
    let index = 0;
    return items ? items.reduce((acc, result) => {
      index += 1;
      acc[`${index}`] = {
        score: scores[`${staffId}-${result.id}`] || '',
        content: result.content,
      };
      return acc;
    }, {}) : {};
  };


  const handleEdit = (record) => {
    setSelectedStaff(record.staffDto.id);
    setCurrentRecord(record);
    form.setFieldsValue(record);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.validateFields().then(values => {
      dataSource.map(item => {
        if (item.key === currentRecord.key) {
          return { ...item, ...values };
        }
        return item;
      });
      saveScores(assessmentData, scores, comments, selectMonth, "assessmentAbilityItems", id, token, false);
      setIsModalVisible(false);
    }).catch(info => {
      console.log('Validate Failed:', info);
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setScores(temporaryStorageScores);
    setComments(temporaryStorageComments);
  };

  const dataSource = assessmentData.map(item => {
    let assessmentResults = processAssessmentResults(item.assessmentAbilityItems, item.staffDto.id);

    const assessmentResultsWithDefault = {};
    for (let i = 1; i <= 10; i++) {
      assessmentResultsWithDefault[`${i}`] = assessmentResults[`${i}`]?.score || 5;
    }

    return {
      key: item.staffDto.id,
      staffDto: item.staffDto,
      ...assessmentResultsWithDefault,
      totalScore: sumScores[item.staffDto.id],
      comments: comments[item.staffDto.id],
      assessmentContents: Object.fromEntries(
        Object.entries(assessmentResults).map(([index, result]) => [index, result.content])
      ),
    };
  });

  const selectedStaffData = useMemo(() => {
    return assessmentData.find(item => item.staffDto.id === selectedStaff);
  }, [assessmentData, selectedStaff]);

  const handleSubmit = () => {
    form.validateFields().then(() => {
      const isAllCommentsFilled = assessmentData.every(item => {
        const staffId = item.staffDto.id;
        return comments[staffId] && comments[staffId].trim() !== '';
      });

      if (isAllCommentsFilled) {
        saveScores(assessmentData, scores, comments, selectMonth, "assessmentAbilityItems", id, token, true);
        setIsAssessmentCompleted(true);
      } else {
        store.dispatch(ACTION_OF.ALERT.ERROR('請填寫所有評語！'));
      }
    }).catch((error) => {
      console.log("Validation failed:", error);
    });
  };


  return (
    <>
      <Flex gap="small" wrap="wrap" style={{ marginBottom: '20px', marginTop: '20px' }}>
        <br />
        <DatePicker picker={'month'} defaultValue={dayjs(selectMonth, 'YYYY-MM')} format={'YYYY/MM'} onChange={setMonth} allowClear={false} />
      </Flex>
      {selectedStaffData && (
        <div key={selectedStaffData}>
          {isAssessmentCompleted ? (
            <Typography.Text>已完成這月份考核</Typography.Text>
          ) : (
            <Form onFinish={handleSubmit} form={form} >
              <div style={{ margin: '0 20px' }}>
                <Table
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  dataSource={dataSource}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }}
                /></div>
              <Modal title={
                <div style={{ textAlign: 'center', width: '100%' }}>
                  {`${selectedStaffData.staffDto.name}-${selectedStaffData.staffDto.nickName} (${selectedStaffData.staffDto.department}-${selectedStaffData.staffDto.jobTitle})`}
                </div>
              }
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="暂存"
                width={"50%"}
              >
                <div style={{ margin: '0 10px' }}>
                  <Table
                    dataSource={selectedStaffData["assessmentAbilityItems"].map(item => ({ ...item, key: item.id }))}
                    columns={columns}
                    pagination={false}
                    width={"100%"}
                    style={{ display: 'flex', justifyContent: 'center', width: 'auto' }}
                    summary={() => {
                      if (!currentRecord || !currentRecord.staffDto) {
                        return null;
                      }
                      return (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={1}>
                              <Typography.Text strong style={{ textAlign: 'center' }}>总分</Typography.Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell colSpan={1}>
                              <Input
                                type="number"
                                min={1} max={50} step={0.1}
                                style={{ width: '100px' }}
                                status={inputStatus}
                                value={sumScores[`${currentRecord.staffDto.id}`] !== undefined ? sumScores[`${currentRecord.staffDto.id}`] : ''}
                                onChange={e => {
                                  let value = e.target.value.trim();
                                  if (value === '') {
                                    calculateTotalScore(currentRecord.staffDto.id, 0);
                                  } else {
                                    value = parseFloat(value);
                                    if (!isNaN(value) && value <= 50) {
                                      value = parseFloat(value.toFixed(1));
                                      calculateTotalScore(currentRecord.staffDto.id, value);
                                      setInputStatus(''); // 输入有效，清空状态
                                    } else {
                                      setInputStatus('error'); // 输入无效，设置状态为 error
                                    }
                                  }
                                }}
                              />
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                          <Table.Summary.Row style={{ textAlign: 'center' }}>
                            <Table.Summary.Cell colSpan={2}>
                              <Typography.Text>評語</Typography.Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                          <Table.Summary.Row>
                            <Table.Summary.Cell colSpan={2}>
                              <Input.TextArea
                                placeholder="評語"
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                maxLength={2000} showCount
                                value={comments[currentRecord.staffDto.id]}
                                onChange={e => handleCommentChange(currentRecord.staffDto.id, e.target.value)}
                                style={{ color: 'black !important' }}
                              />
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      );
                    }}
                  /></div>
              </Modal>
              <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                <Space direction="vertical" style={{ width: '80%' }}>
                </Space>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                <Button type='primary' htmlType='submit'>全部送出</Button>
              </div>
            </Form>
          )}
        </div>
      )}
    </>
  );

};

export default KpiAssessment;
