import {handleActions} from 'redux-actions';
import TYPE_OF from "../action/types";
import produce from 'immer';

const initialState = {
    list: [],
    teatimeList:[]
};

const teatime = handleActions(
    {
        [TYPE_OF.TEATIME.SEARCH_SHOP_MENU.SUCCESS]: produce((draft, {payload}) => {
            draft.list = payload.list;
        }),
        [TYPE_OF.TEATIME.SEARCH_SHOP_MENU.FAILURE]: produce((draft) => {
            draft.list = [];
        }),
        [TYPE_OF.TEATIME.SEARCH_TEATIME.SUCCESS]: produce((draft, {payload}) => {
            draft.teatimeList = payload.list;
        }),
        [TYPE_OF.TEATIME.SEARCH_TEATIME.FAILURE]: produce((draft) => {
            draft.teatimeList = [];
        }),
    },
    initialState
);
export default teatime;