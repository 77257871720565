import React, { useState, useEffect } from 'react';
import { Avatar, Card, Modal, Input, Form, message, Tooltip } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { getPersonalInfo, resetPassword, resetAvatar } from "../api/personal/personal";
import ACTION_OF from "../redux/action";
const { Meta } = Card;

const Personal = () => {
    const { id, token } = useSelector(state => state.session);
    const [personalInfo, setPersonalInfo] = useState([]);
    const [fileName, setFileName] = useState('AndroidOutlined.png');
    const [visible, setVisible] = useState(false);
    const [editAvatar, setEditAvatar] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const calculateWorkingDays = (startDate) => {
        const start = new Date(startDate);
        const today = new Date();
        const diffTime = Math.abs(today - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    };

    const importAll = (r) => r.keys().map(file => file.split('/').pop());
    const images = importAll(require.context('../img/avatar/', false, /\.(png|jpe?g|svg)$/));

    const workingDays = calculateWorkingDays(personalInfo.startWorkDate);
    const formatWorkingDays = (days) => {
        const years = Math.floor(days / 365);
        const months = Math.floor((days % 365) / 30);
        const remainingDays = days % 30;
        return `${years}年 ${months}月 ${remainingDays}天`;
    };
    useEffect(() => {
        const subscription = getPersonalInfo({ staffId: id, token }).subscribe({
            next: response => {
                setPersonalInfo(response);
                setFileName(response.avatar || 'AndroidOutlined.png');
            },
            error: error => {
                console.error("Error fetching Jobtitle: ", error);
            }
        });
        // 返回一个清理函数，取消订阅
        return () => {
            subscription.unsubscribe();
        };
    }, [id, token]);
    // // 开启表格
    const editOutlinedClick = () => {
        setVisible(true);
    }
    const submit = () => {
        form.validateFields().then(async (record) => {
            const { Input1, Input2 } = form.getFieldsValue();
            if (Input1 !== Input2) {
                message.error('兩次輸入的密碼不一致');
                return;
            }
            try {
                const requestBody = Input1;
                await resetPassword(requestBody, { staffId: id, token }).toPromise();
                dispatch(ACTION_OF.SESSION.LOGOUT.REQUEST({ "staffId": id, token }));

            } catch (error) {
                console.error("Error ", error);
            }

            setVisible(false);
        });

    }
    const hideModal = () => {
        setVisible(false);
        setEditAvatar(false);
    }
    const editEditAvatarClick = () => {
        setEditAvatar(true);
    }
    const submitEditAvatar = async (record) => {
        try {
            await resetAvatar(record, { staffId: id, token }).toPromise();
            dispatch(ACTION_OF.SESSION.LOGIN_BY_TOKEN.REQUEST({ token }))
        } catch (error) {
            console.error("Error ", error);
        }
        setFileName(record);
        setEditAvatar(false);
    }

    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Card
                    style={{ width: '80%', marginTop: 16 }}
                >
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}> {/* 使用flex布局将actions放置在顶部右侧 */}
                        <Tooltip title="修改密碼">
                            <EditOutlined key="setting" onClick={editOutlinedClick} />
                        </Tooltip>
                    </div>
                    <Meta
                        avatar={
                            <Tooltip title="點擊修改頭像" >
                                <Avatar
                                    src={require(`../img/avatar/${fileName}`)}
                                    onClick={editEditAvatarClick}
                                    style={{ cursor: 'pointer' }} />
                            </Tooltip>
                        }
                        title={<div style={{ fontSize: '24px' }}>個人資訊</div>}
                        description={<div style={{ fontSize: '16px' }}>
                            名字: {personalInfo.name}
                            <br />
                            暱稱: {personalInfo.nickName}
                            <br />
                            部門: {personalInfo.department}
                            <br />
                            職稱: {personalInfo.jobTitle}
                            <br />
                            到職日: {personalInfo.startWorkDate}
                            <br />
                            年資: {formatWorkingDays(workingDays)}
                        </div>}
                    />
                </Card>
            </div>
            <Modal
                title="修改密碼"
                open={visible}
                onOk={() => submit(form.getFieldsValue())}
                onCancel={hideModal}
                okText="確認"
                cancelText="取消"
            >
                <Form
                    form={form}>
                    <Form.Item
                        label="輸入密碼"
                        name="Input1"
                        rules={[{ required: true, message: 'Please input!' }]}>
                        <Input.Password placeholder="input password" />
                    </Form.Item>
                    <Form.Item
                        label="再次輸入密碼"
                        name="Input2"
                        rules={[{ required: true, message: 'Please input!' }]}>
                        <Input.Password placeholder="input password" />
                    </Form.Item>
                </Form>
            </Modal>
            {/* 修改頭像的modal */}

            <Modal
                title="修改頭像"
                open={editAvatar}
                onCancel={hideModal}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Form>
                    <Form.Item name="avatar" rules={[{ required: true, message: '請選擇頭像!' }]}>
                        <div className="avatar-grid">
                            {images.map(image => (
                                <Avatar size={64} key={image} values={image} src={require(`../img/avatar/${image}`)} onClick={() => submitEditAvatar(image)} />
                            ))}
                        </div>
                    </Form.Item>
                </Form>
            </Modal>

        </>
    )
}

export default Personal;