import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ACTION_OF from '../../redux/action';
import { Table, Select, Modal, Button, Form, Input, Switch, notification, Typography } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { resetPassword } from '../../api/staff';
import store from "../../redux/store";

const Staff = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { list: staffList } = useSelector(state => state.staff);
    const { id, token } = useSelector(state => state.session);
    const [isOpen, setIsOpen] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [jobTitleOptions, setJobTitleOptions] = useState([]);
    const { list: departmentList } = useSelector(state => state.department);
    const { list: jobTitle } = useSelector(state => state.jobTitle);

    //權限轉數字
    const permissionsMapping = {
        General: 1,
        Administrative: 2,
        AdministrativeHigh: 3,
        Financial: 4,
        FinancialHigh: 5,
    };

    useEffect(() => {
        console.log(staffList.length);
        if (staffList.length<1) {
            dispatch(ACTION_OF.STAFF.SEARCH_STAFF.REQUEST({ staffId: id, token }));
        }
        if (departmentList.length<1) {
            dispatch(ACTION_OF.DEPARTMENT.SEARCH_DEPARTMENT.REQUEST({ staffId: id, token }));
        }
        if (jobTitle.length<1) {
            dispatch(ACTION_OF.JOB_TITLE.SEARCH_JOB_TITLE.REQUEST({ staffId: id, token }));
        }
    }, [dispatch, id, token]);


    const generateOptions = useCallback((list) => {
        return list.map(item => (
            <Select.Option key={item.id} value={item.id}>{item.name || item.jobTitle}</Select.Option>
        ));
    }, []);

    useEffect(() => {
        if (departmentList && departmentList.length > 0) {
            const options = generateOptions(departmentList);
            setDepartmentOptions(options);
        }
    }, [departmentList, generateOptions]);

    useEffect(() => {
        if (jobTitle && jobTitle.length > 0) {
            const options = generateOptions(jobTitle);
            setJobTitleOptions(options);
        }
    }, [jobTitle, generateOptions]);

    const handleDoubleClick = (record) => {
        setIsOpen(true);
        form.resetFields();
        form.setFieldsValue(record);
    };

    const handleAdd = () => {
        setIsOpen(true);
        setIsAdding(true);
        form.resetFields();
    };

    const handleCloseModal = () => {
        setIsOpen(false);
        setIsAdding(false);
    };

    const columns = [
        {
            title: '名字',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '暱稱',
            dataIndex: 'nickName',
            key: 'nickName',
        },
        {
            title: '帳號',
            dataIndex: 'loginAccount',
            key: 'loginAccount',
        },
        {
            title: '部門',
            dataIndex: 'department',
            key: 'department',
        },
        {
            title: '職稱',
            dataIndex: 'jobTitle',
            key: 'jobTitle',
        },
        {
            title: '到職日',
            dataIndex: 'startWorkDate',
            key: 'startWorkDate',
        }
    ];
    const Submit = () => {
        form.validateFields().then(() => {
            const fieldsValue = form.getFieldsValue();
            const currentDate = new Date();
            let jobTitleName = typeof fieldsValue.jobTitle === 'number' ? (jobTitle.find(jobTitle => jobTitle.id === fieldsValue.jobTitle)?.jobTitle) : fieldsValue.jobTitle;
            const permissionId = permissionsMapping[jobTitle.find(jobTitle => jobTitle.jobTitle === jobTitleName)?.permissions];
            console.log(permissionId);

            let getValues = { ...fieldsValue, permissions: permissionId };
            if (isAdding) {
                getValues = {
                    ...getValues, jobTitleId: fieldsValue.jobTitle, departmentId: fieldsValue.department,
                    startWorkDate: `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`, loginPassword: "123456"
                }
            } else {
                const staffId = staffList.find(staff => staff.name === fieldsValue.name)?.id;
                const departmentId = typeof fieldsValue.department === 'number' ? fieldsValue.department : (departmentList.find(department => department.name === fieldsValue.department)?.id);
                const jobTitleId = typeof fieldsValue.jobTitle === 'number' ? fieldsValue.jobTitle : (jobTitle.find(jobTitle => jobTitle.jobTitle === fieldsValue.jobTitle)?.id);
                getValues = {
                    ...getValues, id: staffId, jobTitleId: jobTitleId, departmentId: departmentId
                };
            }

            const values = { getValues, staffId: id, token };
            dispatch(ACTION_OF.STAFF.UPDATE_STAFF.REQUEST(values));
            setIsOpen(false);
            setIsAdding(false);
        }).catch(error => {
            store.dispatch(ACTION_OF.ALERT.ERROR(error));
        });
    };

    const resetPwdValue = async () => {
        try {
            const fieldsValue = form.getFieldsValue();
            const resetId = staffList.find(staff => staff.name === fieldsValue.name)?.id;
            const response = await resetPassword(resetId, { staffId: id, token }).toPromise();
            notification.success({
                message: '密碼重置成功',
                description: (
                    <Typography.Paragraph copyable>{response.newPassword}</Typography.Paragraph>
                ),
            });

        } catch (error) {
            store.dispatch(ACTION_OF.ALERT.ERROR('密碼重置失敗'));
        }
    }

    return (<>
        <div style={{ marginBottom: '20px', marginTop: '20px' }}>
            <Button type="primary" onClick={handleAdd}>新增</Button>
        </div>
        <Table dataSource={staffList} columns={columns} rowKey={(record) => record.id}
            onRow={(record) => ({
                onDoubleClick: () => handleDoubleClick(record),
            })} />
        {/* 弹窗 */}
        <Modal
            title={isAdding ? "新增員工" : "修改員工"}
            visible={isOpen}
            onOk={Submit}
            onCancel={handleCloseModal}
            okText="確認"
            cancelText="取消"
        >
            <Form
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 10 }}
            >
                <Form.Item
                    label="名字"
                    name="name"
                    rules={[{ required: true, message: '請輸入名字!' }]}
                >
                    <Input disabled={!isAdding} />
                </Form.Item>
                <Form.Item
                    label="暱稱"
                    name="nickName"
                    rules={[{ required: true, message: '請輸入暱稱!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="帳號"
                    name="loginAccount"
                    rules={[{ required: true, message: '請輸入帳號!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="部門"
                    name="department"
                    rules={[{ required: true, message: '請輸入部門' }]}
                >
                    <Select>
                        {departmentOptions}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="職稱"
                    name="jobTitle"
                    rules={[{ required: true, message: '請輸入職稱!' }]}
                >
                    <Select>
                        {jobTitleOptions}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="在職"
                    name="enable"
                    valuePropName="checked"
                    initialValue={true}
                >
                    <Switch />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Button
                        icon={<RedoOutlined />}
                        onClick={resetPwdValue}
                    >
                        重設密碼
                    </Button></div>
            </Form>
        </Modal>

    </>)
}
export default Staff;