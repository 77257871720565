import React, { useEffect } from "react";
import { Button, Form, Input, Modal } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useDispatch, useSelector } from "react-redux";
import ACTION_OF from "../../redux/action";
import { useHistory } from 'react-router-dom';



const ErrorText = styled.span`
    color: red;
`;
const Login = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const { errorMessage } = useSelector(state => state.session);

    useEffect(() => {
        const token = localStorage.getItem('TOKEN');
        if (token !== null) {
            try {
                dispatch(ACTION_OF.SESSION.LOGIN_BY_TOKEN.REQUEST({ token }));
            } catch (error) {
                console.error("Error ", error);
            }
        }
    }, [dispatch, history]);

    const doLogin = () => {
        form.validateFields().then(values => {
            dispatch(ACTION_OF.SESSION.LOGIN.REQUEST({
                account: values.account,
                passWord: values.passWord,
            }))
        });
    }

    return (
        <div className="login-image">
            <Modal open footer={null} title='登入' className="transparentModal" >
                <Form onFinish={doLogin} form={form}>
                    <Form.Item name="account" rules={[
                        { required: true, message: '請輸入帳號!' },
                        { min: 3, max: 20, message: '帳號長度有誤' }
                    ]}>
                        <Input min={3} max={20}
                            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder='帳號' />
                    </Form.Item>
                    <Form.Item name="passWord" rules={[
                        { required: true, message: '請輸入密碼!' },
                        { min: 6, max: 20, message: '密碼長度有誤' }]}>
                        <Input min={6} max={20}
                            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type='password'
                            placeholder='密碼' />
                    </Form.Item>
                    <Form.Item style={{ textAlign: "right" }}>
                        <ErrorText>{errorMessage}</ErrorText>
                        <Button type='primary' htmlType='submit'> 登入 </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Login;