import {pipe} from "ramda";
import {ofType} from "redux-observable";
import TYPE_OF from "../action/types";
import {pluck, switchMap,mergeMap} from 'rxjs/operators';
import ACTION_OF from "../action";
import {of} from "rxjs";
import catchApiError from "../../http/catchApiError";
import {getKpiAssessment,getMannerAssessment,saveAssessment} from "../../api/assessment";


const searchKpiAssessEpic = pipe(
    ofType(TYPE_OF.ASSESS.SEARCH_ASSESS_KPI.REQUEST),
    pluck('payload'),
    mergeMap(payload => {
        const { selectMonth, staffId, token } = payload; 
        console.log(selectMonth, staffId, token);
        return getKpiAssessment(selectMonth, { "staffId":staffId, token }).pipe( 
            switchMap((response) => of(ACTION_OF.ASSESS.SEARCH_ASSESS_KPI.SUCCESS({list : response}))),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR(message)))
        )
    }),
);

// const searchPersonnelAssessEpic = pipe(
//     ofType(TYPE_OF.ASSESS.SEARCH_ASSESS_PERSONNEL.REQUEST),
//     pluck('payload'),
//     switchMap(payload => {
//         console.log(payload);
//         return getMannerAssessment(payload).pipe(
//             switchMap((response) => of(ACTION_OF.ASSESS.SEARCH_ASSESS_PERSONNEL.SUCCESS({list : response}))),
//             catchApiError(message => of(ACTION_OF.ALERT.ERROR(message)))
//         )
//     }),
// );

// const saveAssessEpic = pipe(
//     ofType(TYPE_OF.ASSESS.SAVE_ASSESS.REQUEST),
//     pluck('payload'),
//     mergeMap(payload => {
//         const { getValues, staffId, token } = payload; 
//         return saveAssessment(getValues, { "staffId":staffId, token }).pipe( 
//             mergeMap(() => {
//                 return of(
//                     ACTION_OF.ASSESS.SAVE_ASSESS.REQUEST( { "staffId":staffId, token })
//                 );
//             }),
//             catchApiError(message => of(ACTION_OF.ALERT.ERROR( message)))
//         )
//     }),
// );


export default {
    searchKpiAssessEpic,
    // searchPersonnelAssessEpic,
    // saveAssessEpic
};
