import {API_HOST, HEADER_TOKEN} from "../index";
import {of, throwError} from "rxjs";
import ACTION_OF from "../../redux/action";
import {catchError, pluck} from "rxjs/operators";
import {ajax} from "rxjs/ajax";

export default (uri, requestBody = {}, headers) => {
    return ajax({
            url: API_HOST + uri,
            method: 'POST',
            headers: {
                ...HEADER_TOKEN,
                'Content-Type': 'application/json',
                ...headers,
            },
            body: requestBody,
        }
    ).pipe(
        catchError(error => {
            if (error.status === 403) {
                of(ACTION_OF.SESSION.INVALID());
            }
            return throwError(error);
        }),
        pluck('response'),
    );

}