import { API_HOST, HEADER_TOKEN } from "../index";
import axios from "axios";


export default (uri, requestBody = {}, headers) => {
    return axios({
        url: API_HOST + uri,
        method: 'POST',
        responseType: 'blob',
        crossDomain: true,
        headers: {
            ...HEADER_TOKEN,
            'Content-Type': 'application/json',
            ...headers,
        },
        data: requestBody,
    })
}