import {combineReducers} from "redux";
import session from "./session";
import department from "./department";
import staff from "./staff";
import assessment from "./assessment";
import jobTitle from "./jobTitle";
import teatime from "./teatime";
import assess from "./assess"

const rootReducer = combineReducers({
    session,
    department,
    staff,
    assessment,
    jobTitle,
    teatime,
    assess
});
export default rootReducer;