// 公布栏编辑
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Form, Modal, Input, Button, Switch, Upload, InputNumber, DatePicker, Typography, Select } from 'antd';
import { FileOutlined, PushpinOutlined, RightOutlined, DownOutlined, EditOutlined, PlusOutlined, UploadOutlined, DeleteTwoTone } from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';
import ACTION_OF from "../../redux/action"
import { getAllBulletin, saveBulletin, updateBulletin, deleteBulletin, uploadFile } from "../../api/bulletin";
import store from "../../redux/store";


const BulletinEdit = () => {
    const { id, token, permissions } = useSelector(state => state.session);
    const [visibleAddBulletin, setVisibleAddBulletin] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [isAdding, setIsAdding] = useState(true);
    const [isReload, setIsReload] = useState(false);
    const [bulletinData, setBulletinData] = useState([]);
    const [bulletinFileurl, setBulletinFileurl] = useState(null);
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [deleteTitle, setDeleteTitle] = useState("");
    const [bulletinId, setBulletinId] = useState();
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);

    const columns = [

        {
            title: '公告日期',
            dataIndex: 'bulletinDate',
            key: 'bulletinDate',
        },
        {
            title: '類別',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '主旨',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                <span>
                    {text} {record.isPin && <PushpinOutlined />}
                </span>
            ),
        },
        {
            title: '附件',
            dataIndex: 'fileurl',
            key: 'fileurl',
            render: (fileurl) => fileurl ? (
                <a href={fileurl} target="_blank" rel="noopener noreferrer">
                    <FileOutlined />
                </a>
            ) : '--',
        },
        ...(permissions === 3 ? [{
            title: '編輯',
            dataIndex: '',
            key: 'x',
            render: (record) => <EditOutlined onClick={() => handleEdit(record)} />,
        }] : [])
    ];

    useEffect(() => {
        let isMounted = true;  // 添加标识符以检查组件是否已卸载

        const fetchBulletinData = async () => {
            try {
                const responseBulletinData = await getAllBulletin({ staffId: id, token }).toPromise();
                if (isMounted) {
                    setBulletinData(responseBulletinData);
                }
            } catch (error) {
                if (isMounted) {
                    store.dispatch(ACTION_OF.ALERT.ERROR(error));
                }
            }
        };

        fetchBulletinData();

        return () => {
            isMounted = false;  // 在清理函数中设置为 false
        };
    }, [id, token, isReload]);

    const handleEdit = (record) => {
        setIsAdding(false);
        setBulletinId(record.id);
        setDeleteTitle(record.title);
        form.setFieldsValue({
            bulletinDate: dayjs(record.bulletindate),
            type: record.type,
            title: record.title,
            content: record.content,
            fileurl: record.fileurl,
            isPin: record.isPin,
        });
        setBulletinFileurl(record.fileurl);
        setVisibleAddBulletin(!visibleAddBulletin);
        console.log(record);
    }
    const reSetModal = () => {
        setIsAdding(true);
        form.setFieldsValue({
            bulletinDate: moment(),
            type: '行政',
            title: null,
            content: null,
            fileurl: null,
            isPin: false,
        });
        setFileList([]);  // 清除 Upload 组件的状态
        setVisibleAddBulletin(!visibleAddBulletin);
    }
    const deleteOldBulletin = async () => {
        try {
            await deleteBulletin(bulletinId, { staffId: id, token }).toPromise();
            store.dispatch(ACTION_OF.ALERT.INFO("删除成功"));
            setIsReload(!isReload);
        } catch (error) {
            store.dispatch(ACTION_OF.ALERT.ERROR(error));
        }
        setDeleteModal(false);
        setVisibleAddBulletin(false);
        setIsReload(!isReload);
        setFileList([]);
    };
    const expandIcon = ({ expanded, onExpand, record }) =>
        expanded ? (
            <DownOutlined onClick={e => onExpand(record, e)} />
        ) : (
            <RightOutlined onClick={e => onExpand(record, e)} />
        );
    const modalSwitch = () => {
        setVisibleAddBulletin(!visibleAddBulletin);
    };
    const addNewBulletin = async () => {
        try {
            const values = await form.validateFields();
            const uploadedFileUrl = await handleUpload(); // Wait for the file upload to complete
            const fileExtension = file.name.split('.').pop(); // 取得副档名
            const requestBody = {
                ...values,
                ...(isAdding ? {} : { 'id': bulletinId }),
                ...(uploadedFileUrl ? { 'fileurl': 'https://imcarpediem.com/download/' + uploadedFileUrl + '.' + fileExtension } : {}),
            };

            console.log(requestBody);

            if (isAdding) {
                await saveBulletin(requestBody, { staffId: id, token }).toPromise();
                store.dispatch(ACTION_OF.ALERT.INFO("添加成功"));
            } else {
                await updateBulletin(requestBody, { staffId: id, token }).toPromise();
                store.dispatch(ACTION_OF.ALERT.INFO("更新成功"));
            }

            setIsReload(!isReload);
            modalSwitch();
            form.resetFields();
            setFileList([]);  // 清除 Upload 组件的状态
        } catch (error) {
            console.log('Validate Failed:', error);
            store.dispatch(ACTION_OF.ALERT.ERROR(error));
        }
    };
    const beforeUpload = (file) => {
        const isPdf = file.type === 'application/pdf';
        if (!isPdf) {
            store.dispatch(ACTION_OF.ALERT.ERROR("只能上传 PDF 文件"));
        }
        setFile(isPdf ? file : null);
        setFileList(isPdf ? [file] : []);
        return false; // Prevent automatic upload
    };

    const handleUpload = async () => {
        if (file) {
            const formData = new FormData();
            const title = form.getFieldValue('title');
            formData.append('file', file);

            try {
                const response$ = uploadFile(title, formData, { staffId: id, token });
                return new Promise((resolve, reject) => {
                    response$.subscribe(
                        response => {
                            console.log(response);
                            setBulletinFileurl(title);
                            store.dispatch(ACTION_OF.ALERT.INFO("文件上傳成功"));
                            setFileList([]);
                            resolve(title);
                        },
                        error => {
                            console.error(error);
                            store.dispatch(ACTION_OF.ALERT.ERROR("文件上傳失敗"));
                            reject(error);
                        }
                    );
                });
            } catch (error) {
                console.log(error);
                store.dispatch(ACTION_OF.ALERT.ERROR("文件上傳失敗"));
                return Promise.reject(error);
            }
        } else {
            return Promise.resolve();
        }
    };
    const handleChange = ({ fileList }) => {
        setFileList(fileList);
    };
    const props = {
        beforeUpload: beforeUpload,
        onChange: handleChange,
        fileList: fileList,
        onRemove: () => {
            setFile(null); // Reset file on remove
            setFileList([]);
        },
        accept: '.pdf', // Accept only PDF files
        showRemoveIcon: true,
    };

    return (
        permissions !== 4 ? (
        <>
            <Modal
                title={isAdding ? "新增公告" : "編輯公告"}
                open={visibleAddBulletin}
                onOk={addNewBulletin}
                onCancel={modalSwitch}
                okText="確認"
                cancelText="取消"
            >
                <Form form={form}
                    initialValues={{
                        bulletinDate: moment(),
                        type: '行政',
                        title: null,
                        content: null,
                        fileurl: null,
                        isPin: false
                    }}>
                    <Form.Item
                        label="公告日期"
                        name="bulletinDate"
                        rules={[{ required: true, message: '請選擇公告日期' }]}
                    >
                        <DatePicker />
                    </Form.Item>
                    <Form.Item
                        label="類別"
                        name="type"
                        rules={[{ required: true, message: '請選擇類別' }]}
                    >
                        <Select
                            // onChange={handleChange}
                            style={{ width: 120, }}
                            options={[
                                { value: '行政', label: '行政' },
                                { value: '人事', label: '人事' },
                                { value: '規章', label: '規章' },
                            ]}>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="主旨"
                        name="title"
                        rules={[{ required: true, message: '請輸入主旨' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="內容"
                        name="content"
                        rules={[{ required: true, message: '請輸入內容' }]}
                    >
                        <Input.TextArea
                            placeholder="請輸入內容，上限600字"
                            maxLength={600}
                            style={{ width: 400 }} />
                    </Form.Item>
                    <Form.Item label="檔案" name="fileurl">
                        {bulletinFileurl ? (
                            <a href={bulletinFileurl} target="_blank" rel="noopener noreferrer">
                                <FileOutlined />
                            </a>
                        ) : "--"}
                    </Form.Item>
                    <Form.Item >
                        <Upload maxCount={1} {...props}>
                            <Button icon={<UploadOutlined />}>選擇文件</Button>
                        </Upload>

                    </Form.Item>
                    <Form.Item label="置頂" name='isPin' valuePropName="checked">
                        <Switch />
                    </Form.Item>
                    {isAdding ? null :
                        <Form.Item label="刪除" name="delete">
                            <DeleteTwoTone style={{ fontSize: '26px' }}
                                onClick={() => setDeleteModal(true)}
                            />
                        </Form.Item>}

                </Form>
            </Modal>
            <Modal
                title="刪除"
                open={deleteModal}
                onOk={deleteOldBulletin}
                onCancel={() => setDeleteModal(false)}
                okText="確認"
                cancelText="取消">
                <p>確定刪除公告: {deleteTitle}?</p>

            </Modal>
            
            <Typography.Title >公告</Typography.Title>
            <Table
                columns={columns}
                rowKey={record => record.id}
                expandable={{
                    expandIcon: expandIcon,
                    expandedRowRender: (record) => (
                        <p
                            style={{
                                margin: 0,
                            }}
                        >
                            {record.content}
                        </p>
                    ),
                }}
                dataSource={bulletinData}
                footer={() => (
                    permissions === 3 ? (
                        <div style={{ textAlign: 'right' }}>
                            <Button type="primary" icon={<PlusOutlined />} onClick={reSetModal} >
                                新增
                            </Button>
                        </div>
                    ) : null
                )}
            />
        </>):("hello")
    )
};

export default BulletinEdit;
