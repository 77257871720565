import Post from "../../http/Post";
import Upload from "../../http/Upload";

export const getAllBulletin= (headers) => Post('/demo/bulletin/getAllBulletin', {}, headers);

export const saveBulletin= (requestBody, headers) => Post('/demo/bulletin/saveBulletin', requestBody, headers);

export const updateBulletin= (requestBody, headers) => Post('/demo/bulletin/updateBulletin', requestBody, headers);

export const deleteBulletin= (requestBody, headers) => Post('/demo/bulletin/deleteBulletin', requestBody, headers);

export const uploadFile = (title, formData, headers) => {
    const url = `/demo/upload/bulletinFileUpload?titleName=${encodeURIComponent(title)}`;
    return Upload(url, formData, headers);
};