import React, { useEffect } from "react";
import Login from "./pages/Login";
import { useDispatch, useSelector } from "react-redux";
import Layout from "./Layout";
import ACTION_OF from "./redux/action";


export default () => {

    const { isLogin } = useSelector(state => state.session);
    const dispatch = useDispatch();
    const staffData = JSON.parse(window.localStorage.getItem("staff"));
    const token = staffData ? staffData.token : null;
    useEffect(() => {
        if (token && !isLogin) {
            dispatch(ACTION_OF.SESSION.LOGIN_BY_TOKEN.REQUEST ({ token: token }))
        }
    }, [token, isLogin, dispatch])

    return (<>{
        isLogin ? <Layout /> : <Login />
    }</>);
}