// import {toast} from 'react-toastify';
import TYPE_OF from "../action/types";
import { message  } from 'antd';


export const toastMiddleware = store => next => action => {
    if (action.type === TYPE_OF.ALERT.INFO) {
        message.success(action.payload);
    }
    if (action.type === TYPE_OF.ALERT.ERROR) {
        message.error(action.payload);
    }

    return next(action);
};
