//能力考核表编辑页面
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Form, Select, Modal, Button, Typography } from 'antd';
import ACTION_OF from "../../../redux/action";
import store from "../../../redux/store";
import { from } from "rxjs";

const AbilityAssessmentForm = React.memo(({ assessmentType }) => {
    const { id, token } = useSelector(state => state.session);
    const { list: assessment, jobAssessment } = useSelector(state => ({
        list: state.assessment.list,
        jobAssessment: state.assessment.jobList[assessmentType]
    }));
    const { list: jobTitle } = useSelector(state => state.jobTitle);
    const [assessmentByJobTitle, setAssessmentByJobTitle] = useState([]);
    const [selectDefaultValue, setSelectDefaultValue] = useState([]);
    const [jobTitleOptions, setJobTitleOptions] = useState([]);
    const [assessmentOptions, setAssessmentOptions] = useState([]);
    const [visible, setVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [currentJobTitleId, setCurrentJobTitleId] = useState();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [isAdding, setIsAdding] = useState(false);


    useEffect(() => {
        dispatch(ACTION_OF.ASSESSMENT.SEARCH_ASSESSMENT.REQUEST({ staffId: id, token }));
        dispatch(ACTION_OF.JOB_TITLE.SEARCH_JOB_TITLE.REQUEST({ staffId: id, token }));
    }, [dispatch, id, token]);

    useEffect(() => {
        dispatch(ACTION_OF.ASSESSMENT.SEARCH_ALL_JOB_ASSESSMENT.REQUEST({ staffId: id, token }));
    }, [dispatch,id,token]);

    useEffect(() => {
        if (jobTitle && jobTitle.length > 0) {
            const options = jobTitle.map(item => (
                <Select.Option key={item.id} value={item.id}>{item.jobTitle}</Select.Option>
            ));
            setJobTitleOptions(options);
        }
        if (assessment && assessment.length > 0) {
            const options = assessment.map(item => {
                if (item.assessmentType === (assessmentType === 'attitude' ? '工作態度' : '工作能力')) {
                    return <Select.Option key={item.id} value={item.id}>{item.content}</Select.Option>;
                } else {
                    return null;
                }
            });
            setAssessmentOptions(options);
        }

    }, [jobTitle, assessment,assessmentType]);


    useEffect(() => {
        if (currentJobTitleId && jobAssessment && assessment) {
            const filteredAssessment = jobAssessment.find(item => item.jobTitleId === currentJobTitleId);

            if (filteredAssessment) {
                const assessmentContents = filteredAssessment.assessmentItems.map(itemId => {
                    const assessmentItem = assessment.find(assessmentItem => assessmentItem.id === itemId);
                    return assessmentItem ? { id: assessmentItem.id, content: assessmentItem.content } : null;
                });
                if (assessmentContents < 1) { setVisible(true) } else { setVisible(false) }
                setAssessmentByJobTitle(assessmentContents);
            }
        }
    }, [currentJobTitleId, jobAssessment,assessment]);


    const columns = [
        {
            title: '考核項目',
            dataIndex: 'content',
            key: 'content',
        },
    ];



    const handleChange = (value) => {
        setCurrentJobTitleId(value);
    }

    const handleButtonClick = () => {
        form.setFieldsValue({ assessmentItemIds: [] });
        setSelectDefaultValue([]);
        setIsAdding(true);
        setModalVisible(true);
    };

    const handleSelect = (selectedValues) => {
        let num = assessmentType === 'attitude' ? 5 : 10;
        if (selectedValues.length > num) {
            store.dispatch(ACTION_OF.ALERT.ERROR('最多只能選' + num + '項'));
            return;
        }
    };

    const handleCancel = () => {
        form.setFieldsValue({ assessmentItemIds: [] });
        setIsAdding(false);
        setModalVisible(false);
        setSelectDefaultValue([]);
    };
    //送出新增/修改
    const handleSubmit = () => {
        form.validateFields().then(() => {
            const fieldsValue = form.getFieldsValue();
            let getValues = {};
            const newIds = fieldsValue.assessmentItemIds;
            if (assessmentByJobTitle.length > 0) {
                const oldIds = assessmentByJobTitle.map(item => item.id);
                getValues = { ...fieldsValue, jobTitleId: currentJobTitleId, oldIds, newIds };
                const values = { getValues, staffId: id, token };
                dispatch(ACTION_OF.ASSESSMENT.UPDATE_JOB_ASSESSMENT.REQUEST(values))

            } else {
                getValues = { ...fieldsValue, jobTitleId: currentJobTitleId, newIds };
                const values = { getValues, staffId: id, token };
                dispatch(ACTION_OF.ASSESSMENT.ADD_JOB_ASSESSMENT.REQUEST(values));
            }
            setVisible(false);
            setModalVisible(false);
        }).catch(errorInfo => {
            console.error('表单校验失败:', errorInfo);
        });
    };

    //連點編輯
    const handleDoubleClick = () => {
        const newDefaultValue = assessmentByJobTitle.map(item => item.id);
        setSelectDefaultValue(newDefaultValue);
        setModalVisible(true);
        form.setFieldsValue({ assessmentItemIds: newDefaultValue });
    };

    return (
        <>
            <Typography.Title level={3}>職稱</Typography.Title>
            <Select
                style={{ width: 200 }}
                onChange={(value, option) => handleChange(value, option)}
                defaultValue={currentJobTitleId}
            >
                {jobTitleOptions}
            </Select>
            <Table dataSource={assessmentByJobTitle} columns={columns}
                rowKey={(record) => record.id}
                onRow={() => ({
                    onDoubleClick: () => handleDoubleClick(),
                })} />
            <br />
            {visible && <Button type="primary" onClick={handleButtonClick}>新增</Button>}

            <Modal
                title={isAdding ? "新增考核表" : "修改考核表"}
                open={modalVisible}
                onOk={handleSubmit}
                onCancel={handleCancel}
            >
                <Form
                    form={form}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    <Form.Item
                        label="考核項目"
                        name="assessmentItemIds"
                        initialValue={selectDefaultValue}
                    >
                        <Select
                            mode="multiple"
                            size="middle"
                            placeholder="請選擇考核項目!"
                            onChange={handleSelect}
                            style={{ width: '100%' }}
                            maxTagCount={assessmentType === 'attitude' ? 5 : 10}
                        >
                            {assessmentOptions}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
});
export default AbilityAssessmentForm;