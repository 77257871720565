import {pipe} from "ramda";
import {ofType} from "redux-observable";
import TYPE_OF from "../action/types";
import {pluck, switchMap,mergeMap} from 'rxjs/operators';
import ACTION_OF from "../action";
import {of} from "rxjs";
import catchApiError from "../../http/catchApiError";
import {getDepartmentList,saveOrUpdateDepartment} from "../../api/department";


const searchDepartmentListEpic = pipe(
    ofType(TYPE_OF.DEPARTMENT.SEARCH_DEPARTMENT.REQUEST),
    pluck('payload'),
    switchMap(payload => {
        
        return getDepartmentList(payload).pipe(
            switchMap((response) => of(ACTION_OF.DEPARTMENT.SEARCH_DEPARTMENT.SUCCESS({list : response}))),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR(message)))
        )
    }),
);

const updateDepartmentListEpic = pipe(
    ofType(TYPE_OF.DEPARTMENT.UPDATE_DEPARTMENT.REQUEST),
    pluck('payload'),
    mergeMap(payload => {
        console.log(payload);
        const { getValues, staffId, token } = payload; // 解构出所需的值
        console.log(getValues); // 这里打印出 values
        console.log(staffId); // 这里打印出 staffId
        console.log(token); // 这里打印出 token
        return saveOrUpdateDepartment(getValues, { "staffId":staffId, token }).pipe( // 传递到 API 调用中
            mergeMap(() => {
                return of(
                    ACTION_OF.DEPARTMENT.SEARCH_DEPARTMENT.REQUEST( { "staffId":staffId, token })
                );
            }),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR( message)))
        )
    }),
);


export default {
  searchDepartmentListEpic,
  updateDepartmentListEpic
};
