import Post from "../../http/Post";
import Excel from "../../http/Excel";

export const summaryForm= (requestBody, headers)  => Excel('/demo/form/summaryForm', requestBody, headers);

export const assessFrom= (requestBody, headers)  => Excel('/demo/form/assessFrom', requestBody, headers);

export const getNotAssessBySelf= (requestBody, headers)  => Post('/demo/administrative/getNotAssessBySelf', requestBody, headers);

export const getAllAssessment= (requestBody, headers)  => Post('/demo/administrative/getAllAssessment', requestBody, headers);
