import Post from "../../http/Post";

export const getKpiAssessment= (requestBody, headers) => Post('/demo/form/getPersonalAssessmentForm', requestBody, headers);

export const getMannerAssessment= (requestBody, headers) => Post('/demo/form/getAttitudeAssessmentForm', requestBody, headers);

export const saveAssessment= (requestBody, headers)  => Post('/demo/form/saveAssessment', requestBody, headers);

export const getAssessmentItem=  headers  => Post('/demo/administrative/getAllAssessmentItem', {}, headers);

export const addAssessmentItem=  (requestBody, headers)  => Post('/demo/administrative/addAssessmentItem', requestBody, headers);

export const getAllJobtitle =  headers => Post(`/demo/administrative/getJobTitleList`,{},headers);

export const getAllJobTitleAssessmentItem =  (requestBody, headers) => Post(`/demo/administrative/getAllJobTitleAssessmentItem`,requestBody,headers);

export const addAssessmentForm =  (requestBody, headers) => Post(`/demo/administrative/addAssessmentForm`,requestBody,headers);

export const updateAssessmentForm =  (requestBody, headers) => Post(`/demo/administrative/updateAssessmentForm`,requestBody,headers);
