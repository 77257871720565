import React from 'react';
import { Form, Input, InputNumber } from 'antd';
import ACTION_OF from "../../redux/action";
import store from "../../redux/store";
import { saveAssessment } from '../../api/assessment';


export const saveScores = async (assessmentData, scores, comments, selectMonth, assessmentType, id, token, submitValue) => {
  const currentDate = new Date();
  try {
    const assessmentResults = [];
    const assessmentComments = [];

    // 使用 Set 来存储已经添加评论的员工，以确保不重复添加评论
    const addedComments = new Set();

    for (const [key, value] of Object.entries(scores)) {
      const [staffId, itemId] = key.split('-').map(item => parseInt(item));

      // 添加评分项
      assessmentResults.push({
        staffId: parseInt(staffId),
        assessmentItemId: parseInt(itemId),
        assessmentItemScore: value,
        assessDate: selectMonth,
        assessedBy: (id === staffId ? 'self' : 'superior'),
        submit: submitValue
      });

      // 检查是否已经添加评论，如果未添加则添加评论
      if (!addedComments.has(staffId)) {
        assessmentComments.push({
          staffId: parseInt(staffId),
          comment: (assessmentType === 'assessmentAttitudeItems' ? 'GOOD' : comments[staffId]),
          assessDate: selectMonth,
          assessedBy: (id === staffId ? 'self' : 'superior'),
          submit: submitValue,
          commentType: (assessmentType === 'assessmentAttitudeItems' ? '工作態度' : '工作能力')
        });
        // 将员工添加到 Set 中，表示已添加评论
        addedComments.add(staffId);
      }
    }

    const requestData = {
      assessmentResults: assessmentResults,
      assessmentComments: assessmentComments
    };

    await saveAssessment(requestData, { staffId: id, token }).toPromise();
    if (submitValue) {
      store.dispatch(ACTION_OF.ALERT.INFO('已完成這月份考核'));
    }
  } catch (error) {
    store.dispatch(ACTION_OF.ALERT.ERROR(error));
  }
};

export const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  inputType,
  editing,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `请输入 ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const departmentFilters = (assessmentData)=>{
  const uniqueDepartments=[...new Set(assessmentData.map(item => item.staffDto.department))];
  return uniqueDepartments.map(department => ({
    text: department,
    value: department,
  }))
};