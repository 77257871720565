import React, { useState, useEffect, useMemo } from "react";
import { Button, Layout, Menu, Avatar } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import AbilityAssessment from "./pages/assessment/kpiAssessment";
import AttitudeAssessment from "./pages/assessment/attitudeAssessment";
import Staff from "./pages/edit/staff";
import JobTitle from "./pages/edit/jobtitle";
import Department from "./pages/edit/department";
import TeatimeEdit from "./pages/edit/teatimeEdit";
import Wishingwell from "./pages/wishingwell";
import AbilityAssessmentForm from "./pages/edit/assessment/abilityAssessmentForm";
import AttitudeAssessmentForm from "./pages/edit/assessment/attitudeAssessmentForm";
import BulletinEdit from "./pages/edit/bulletin";
import AssessmentItem from "./pages/edit/assessment/assessmentItem";
import AssessmentStatistics from "./pages/statistics/assessmentStatistics";
import WishingWellStatistics from "./pages/statistics/wishingWellStatistics";
import AssessmentRecord from "./pages/assessmentRecord";
import TeatimeStatistics from "./pages/statistics/teatimeStatistics";
import TeaTimeMenu from "./pages/teatimeMenu";
import Personal from "./pages/personal";
import menuItems from "./menu/menuItem";
import ACTION_OF from "./redux/action";
import PermissionRoute from "./menu/withPermission"; // 导入高阶组件

export default () => {
    const [collapsed, setCollapsed] = useState(false);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const [selectedKey, setSelectedKey] = useState(location.pathname);

    const onCollapse = () => {
        setCollapsed(!collapsed);
    };

    useEffect(() => {
        setSelectedKey(location.pathname);
    }, [location]);

    const handleMenuClick = ({ key }) => {
        if (selectedKey !== key) {
            setSelectedKey(key);
            history.push(key);
        }
    };

    const { id, token, nickName, avatar, permissions } = useSelector(state => state.session);

    const doLogOut = () => {
        dispatch(ACTION_OF.SESSION.LOGOUT.REQUEST({
            "staffId": id,
            token,
        }));
    }

    const menuItemsMemo = useMemo(() => menuItems(permissions), [permissions]);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Layout.Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
                <div className="logo" style={{ height: '2vh' }} />
                <Menu
                    theme="dark"
                    selectedKeys={[selectedKey]}
                    mode="inline"
                    items={menuItemsMemo}
                    onClick={handleMenuClick} />
            </Layout.Sider>
            <Layout className="site-layout">
                <Layout.Header className="site-layout-background" style={{
                    height: '6vh',
                    fontSize: '18px',
                    color: "#001529",
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    background: "#FFF"
                }}>
                    <div style={{
                        fontSize: '14px',
                        color: "#001529"
                    }}>
                        <span>
                            歡迎您，
                            <Avatar
                                src={require(`./img/avatar/${(avatar == null ? 'AndroidOutlined.png' : avatar)}`)}
                                onClick={() => history.push("/personal")}
                                style={{ cursor: 'pointer' }} />
                            {nickName}
                        </span>
                        <Button type='link' icon={<LogoutOutlined />} onClick={doLogOut}>登出</Button>
                    </div>
                </Layout.Header>
                <Layout.Content style={{ margin: '0 16px' }}>
                    <Switch>
                        <PermissionRoute exact path='/' allowedPermissions={[1, 2, 3, 4]} component={BulletinEdit} />
                        <PermissionRoute exact path='/staff' allowedPermissions={[3]} component={Staff} />
                        <PermissionRoute exact path='/department' allowedPermissions={[3]} component={Department} />
                        <PermissionRoute exact path='/abilityAssessment' allowedPermissions={[1, 2, 3, 4]} component={AbilityAssessment} />
                        <PermissionRoute exact path='/assessmentRecord' allowedPermissions={[1, 2, 3, 4]} component={AssessmentRecord} />
                        <PermissionRoute exact path='/attitudeAssessment' allowedPermissions={[3]} component={AttitudeAssessment} />
                        <PermissionRoute exact path='/jobTitle' allowedPermissions={[3]} component={JobTitle} />
                        <PermissionRoute exact path='/wishingwell' allowedPermissions={[1, 2, 3]} component={Wishingwell} />
                        <PermissionRoute exact path='/abilityAssessmentForm' allowedPermissions={[3]} component={AbilityAssessmentForm} />
                        <PermissionRoute exact path='/attitudeAssessmentForm' allowedPermissions={[3]} component={AttitudeAssessmentForm} />
                        <PermissionRoute exact path='/assessmentItem' allowedPermissions={[3]} component={AssessmentItem} />
                        <PermissionRoute exact path='/personal' allowedPermissions={[1, 2, 3, 4]} component={Personal} />
                        <PermissionRoute exact path='/teatimeEdit' allowedPermissions={[2, 3]} component={TeatimeEdit} />
                        <PermissionRoute exact path='/assessmentStatistics' allowedPermissions={[3]} component={AssessmentStatistics} />
                        <PermissionRoute exact path='/bulletinEdit' allowedPermissions={[3]} component={BulletinEdit} />
                        <PermissionRoute exact path='/wishingWellStatistics' allowedPermissions={[3]} component={WishingWellStatistics} />
                        <PermissionRoute exact path='/teatimeStatistics' allowedPermissions={[2, 3]} component={TeatimeStatistics} />
                        <PermissionRoute exact path='/teatimeMenu' allowedPermissions={[1, 2, 3]} component={TeaTimeMenu} />
                    </Switch>
                </Layout.Content>
            </Layout>
        </Layout>
    );
};
