//下午茶统计
import React, { useState, useEffect } from "react";
import { Row, Col, Table, Button } from "antd";
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import {
    getTeatimeOrderStatistics,
    getTeatimeOrderTotalPrice,
    getFoodTeatimeStatistics,
    getSideDishTeatimeStatistics,
    getDrinkStatistics,
    getDrinkTeatimeStatistics,
    teatimeStatisticsForm
} from "../../api/statistics/teatimeStatistics";
import { useSelector } from "react-redux";
import ACTION_OF from "../../redux/action";
import store from "../../redux/store";
import download from 'downloadjs';

const TeatimeStatistics = () => {
    const { id, token } = useSelector(state => state.session);
    const [statistics, setStatistics] = useState([]);
    const [sideDish, setSideDish] = useState([]);
    const [drink, setDrink] = useState([]);
    const [food, setFood] = useState([]);
    const [drinkDetailed, setDrinkDetailed] = useState([]);
    const [totalPrice, setTotalPrice] = useState([]);
    const [drinkSwitch, setDrinkSwitch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);



    const columns = [
        { title: '部門', dataIndex: 'dep', key: 'dep' },
        { title: '暱稱', dataIndex: 'nickName', key: 'nickName' },
        { title: '餐點', dataIndex: 'meal', key: 'meal' },
        { title: '飲料', dataIndex: 'drink', key: 'drink' },
        { title: '甜度', dataIndex: 'sugar', key: 'sugar' },
        { title: '冰塊', dataIndex: 'ice', key: 'ice' },
        { title: '附餐', dataIndex: 'sideDish', key: 'sideDish' },
    ];
    const foodColumns = [
        { title: '數量', dataIndex: 'count', key: 'count' },
        { title: '餐點', dataIndex: 'itemName', key: 'itemName' },
        { title: '總金額', dataIndex: 'totalPrice', key: 'totalPrice' },
    ];
    const sideDishColumns = [
        { title: '數量', dataIndex: 'count', key: 'count' },
        { title: '附餐', dataIndex: 'itemName', key: 'itemName' },
        { title: '總金額', dataIndex: 'totalPrice', key: 'totalPrice' },
    ];
    const drinkColumns = [
        { title: '數量', dataIndex: 'count', key: 'count' },
        { title: '飲料', dataIndex: 'itemName', key: 'itemName' },
        { title: '總金額', dataIndex: 'totalPrice', key: 'totalPrice' },
    ];
    const drinkDetailedColumns = [
        { title: '數量', dataIndex: 'count', key: 'count' },
        { title: '飲料', dataIndex: 'drinkName', key: 'drinkName' },
        { title: '甜度冰塊', dataIndex: 'sugarIce', key: 'sugarIce' },
    ];
    const totalPriceColumns = [
        { title: '餐點金額', dataIndex: 'foodPrice', key: 'foodPrice' },
        { title: '附餐金額', dataIndex: 'sideDishPrice', key: 'sideDishPrice' },
        { title: '飲料金額', dataIndex: 'drinkPrice', key: 'drinkPrice' },
        { title: '總金額', dataIndex: 'total', key: 'total' },
    ];
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [
                    response,
                    drinkResponse,
                    foodResponse,
                    sideDishResponse,
                    drinkDetailedResponse,
                    totalPriceResponse
                  ] = await Promise.all([
                    getTeatimeOrderStatistics({ staffId: id, token }).toPromise(),
                    getDrinkTeatimeStatistics({ staffId: id, token }).toPromise(),
                    getFoodTeatimeStatistics({ staffId: id, token }).toPromise(),
                    getSideDishTeatimeStatistics({ staffId: id, token }).toPromise(),
                    getDrinkStatistics({ staffId: id, token }).toPromise(),
                    getTeatimeOrderTotalPrice({ staffId: id, token }).toPromise()
                  ]);
                  setStatistics(response);
                  setDrink(drinkResponse);
                  setFood(foodResponse);
                  setSideDish(sideDishResponse);
                  setDrinkDetailed(drinkDetailedResponse);
                  setTotalPrice(totalPriceResponse);                  
            } catch (error) {
                store.dispatch(ACTION_OF.ALERT.ERROR(error));
            }
        };
        fetchData();
    }, [id,token])
    const downloadTeatimeStatisticsForm = async () => {
        try {
            setIsLoading(true);
            await teatimeStatisticsForm({ staffId: id, token }).then((res) => {
                download(res.data, '下午茶統計表.xlsx')
            })
                .catch(error => {
                    console.error('There has been a problem with your fetch operation:', error);
                }).finally(() => {
                    setIsLoading(false);
                });
        } catch (error) {
            console.error('Error:', error);
        }

    }

    return (
        <>
            <br />
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Table title={() => "餐點"} dataSource={food} columns={foodColumns} pagination={false} rowKey={(record)=>record.id}/>
                </Col>
                <Col span={8}>
                    {drinkSwitch ?
                        <Table title={() => <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>飲料</span>
                            <Button type="primary" onClick={() => setDrinkSwitch(prevSwitch => !prevSwitch)} >統計</Button>
                        </div>} dataSource={drinkDetailed} columns={drinkDetailedColumns} pagination={false} rowKey={(record)=>record.id}/>
                        :
                        <Table title={() => <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>飲料</span>
                            <Button type="primary" onClick={() => setDrinkSwitch(prevSwitch => !prevSwitch)} >詳細</Button>
                        </div>} dataSource={drink} columns={drinkColumns} pagination={false} rowKey={(record)=>record.id} />}
                </Col>
                <Col span={8}>
                    <Table title={() => "附餐"} dataSource={sideDish} columns={sideDishColumns} pagination={false} rowKey={(record)=>record.id}/>
                </Col>
            </Row>
            <br />
            <Table title={() => "金額"} dataSource={totalPrice} columns={totalPriceColumns} pagination={false}  rowKey={(record)=>record.id}/>
            <br />
            <Table dataSource={statistics} columns={columns} rowKey={(record)=>record.id}
                title={() => <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <span>統計表</span>
                    <Button 
                    type="primary" 
                    onClick={downloadTeatimeStatisticsForm} 
                    icon={isLoading ? <LoadingOutlined /> : <DownloadOutlined />}>下載</Button>
                </div>}
            />
        </>
    )
}
export default TeatimeStatistics;