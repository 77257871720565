import React, { useState, useEffect } from "react";
import { Table, Form, Input, Modal, Select, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ACTION_OF from "../../redux/action";
import store from "../../redux/store";
const Department = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { list: staffList } = useSelector(state => state.staff);
    const { list } = useSelector(state => state.department);
    const { id, token } = useSelector(state => state.session);
    const [visible, setVisible] = useState(false);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [staffOptions, setStaffOptions] = useState([]);
    const [isAdding, setIsAdding] = useState(false);

    useEffect(() => {
        if (staffList.length<1) {
            dispatch(ACTION_OF.STAFF.SEARCH_STAFF.REQUEST({ staffId: id, token }));
        }
        if (list.length<1) {
            dispatch(ACTION_OF.DEPARTMENT.SEARCH_DEPARTMENT.REQUEST({ staffId: id, token }));
        }
    }, [dispatch, id, token]);

    const departmentInfo = [
        { title: '部門', dataIndex: 'name', key: 'name' },
        { title: '負責人', dataIndex: 'manager', key: 'manager' },
        { title: '上級部門', dataIndex: 'pdepartment', key: 'pdepartment' },
    ];

    useEffect(() => {
        if (list && list.length > 0) {
            // 构建部门名称选项列表
            const options = list.map(item => (
                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
            ));
            console.log(options);
            setDepartmentOptions(options);
        }
        if (staffList && staffList.length > 0) {
            const options = staffList.map(item => (
                <Select.Option key={item.id} value={item.id}>{item.name}－{item.nickName}</Select.Option>
            ));
            setStaffOptions(options);
        }
    }, [list, staffList]);


    const handleDoubleClick = (record) => {
        setVisible(true);
        form.resetFields();
        form.setFieldsValue(record);
    };

    const handleSubmit = () => {
        // 提交表单数据
        form.validateFields().then(() => {
            const fieldsValue = form.getFieldsValue();
            console.log(fieldsValue.manager);
            const staffId = typeof fieldsValue.manager === 'number' ? fieldsValue.manager : (staffList.find(staff => staff.name === fieldsValue.manager)?.id);
            const pdepartmentId = typeof fieldsValue.pdepartment === 'number' ? fieldsValue.pdepartment : (list.find(department => department.name === fieldsValue.pdepartment)?.id);
            const departmentId = list.find(department => department.name === fieldsValue.name)?.id;
            const getValues = { ...fieldsValue, pid: pdepartmentId, managerId: staffId, id: (isAdding ? "" : departmentId) };
            const values = { getValues, staffId: id, token };
            // 调用更新部门列表的 Epic
            dispatch(ACTION_OF.DEPARTMENT.UPDATE_DEPARTMENT.REQUEST(values));
            setVisible(false);
        }).catch(error => {
            store.dispatch(ACTION_OF.ALERT.ERROR(error));
        });
    };

    const handleAdd = () => {
        setVisible(true);
        setIsAdding(true);
        form.resetFields();
    };

    const handleCancel = () => {
        setVisible(false);
        setIsAdding(false);
    };

    return (<>
        <div style={{ marginBottom: '20px', marginTop: '20px' }}>
            <Button type="primary" onClick={handleAdd}>新增</Button>
        </div>
        <Table
            dataSource={list}
            columns={departmentInfo}
            rowKey={(record) => record.id}
            onRow={(record) => ({
                onDoubleClick: () => handleDoubleClick(record),
            })}
        />
        <Modal
            title={isAdding ? "新增部門" : "修改部門"}
            visible={visible}
            onOk={handleSubmit}
            onCancel={handleCancel}
            okText="確認"
            cancelText="取消"
        >
            <Form
                form={form}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
            >
                <Form.Item
                    label="部門"
                    name="name"
                    rules={[{ required: true, message: '請輸入部門!' }]}
                >
                    <Input disabled={!isAdding} />
                </Form.Item>
                <Form.Item
                    label="負責人"
                    name="manager"
                    rules={[{ required: true, message: '請輸入負責人!' }]}
                >
                    <Select>
                        {staffOptions}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="上級部門"
                    name="pdepartment"
                >
                    <Select>
                        <Select.Option key="" value="">無</Select.Option>
                        {departmentOptions}
                    </Select>

                </Form.Item>
            </Form>
        </Modal>
    </>
    );
}

export default Department;
