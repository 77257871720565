import {handleActions} from 'redux-actions';
import TYPE_OF from "../action/types";
import produce from 'immer';

const initialState = {
    list: []
};


const assess = handleActions(
    {
        [TYPE_OF.ASSESS.SEARCH_ASSESS_KPI.SUCCESS]: produce((draft, {payload}) => {
            draft.list = payload.list;
        }),
        [TYPE_OF.ASSESS.SEARCH_ASSESS_KPI.FAILURE]: produce((draft) => {
            draft.list = [];
        })
    },
    initialState
);

export default assess;