// 許願池統計表
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { getAssessmentRecord } from '../api/assessmentRecord';
import { Table, Flex, DatePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';
const AssessmentRecord = () => {
    const { id, token } = useSelector(state => state.session);
    const [selectMonth, setSelectMonth] = useState(dayjs().date() <= 20 ? dayjs().subtract(1, 'month').format('YYYY-MM') : dayjs().format('YYYY-MM'))
    // const [assessmentRecords, setAssessmentRecords] = useState([]);
    const [columnData, setColumnData] = useState([]);

    const setMonth = (date) => {
        setSelectMonth(date.format('YYYY-MM'));
        console.log("Selected date:", selectMonth); // 记录格式化日期
    };
    const generateScoreColumn = (index, dataIndex, width, content) => ({
        title: `項目${index}`,
        dataIndex: dataIndex,
        key: dataIndex,
        width: width,
        render: (score, record) => (
            <Tooltip placement="left" title={record[content]}>
                {score}
            </Tooltip>
        ),
    });
    const columns = [
        {
            title: '名字',
            dataIndex: 'name',
            key: 'name',
            width: 25,
        },
        generateScoreColumn(1, 'score1', 20, 'content1'),
        generateScoreColumn(2, 'score2', 20, 'content2'),
        generateScoreColumn(3, 'score3', 20, 'content3'),
        generateScoreColumn(4, 'score4', 20, 'content4'),
        generateScoreColumn(5, 'score5', 20, 'content5'),
        generateScoreColumn(6, 'score6', 20, 'content6'),
        generateScoreColumn(7, 'score7', 20, 'content7'),
        generateScoreColumn(8, 'score8', 20, 'content8'),
        generateScoreColumn(9, 'score9', 20, 'content9'),
        generateScoreColumn(10, 'score10', 20, 'content10'),

        {
            title: '總分',
            dataIndex: 'totalScore',
            key: 'totalScore',
            width: 20,
        },
        {
            title: '自評',
            dataIndex: 'assessmentComments',
            key: 'assessmentComments',
            width: 60,
            ellipsis: {
                showTitle: false,
            },
            render: (comment) => (
                <Tooltip placement="left" title={comment}>
                    {comment}
                </Tooltip>
            )
        }
    ];
    useEffect(() => {
        const headers = {
            'staffId': id,
            token,
        };
        const requestBody = selectMonth;
        const fetchData = async () => {
            try {
                const response = await getAssessmentRecord(requestBody, headers).toPromise();
                console.log('response', response);
                const formattedData = response.map(assessmentRecord => {

                    const result = {
                        name: assessmentRecord.staffDto.name,
                        id: assessmentRecord.staffDto.id
                    };
                    let totalScore = 0;
                    assessmentRecord.assessmentDetail.forEach((item, index) => {
                        const score = parseFloat(item.assessmentItemScore);
                        result[`score${index + 1}`] = score;
                        result[`content${index + 1}`] = item.content;
                        totalScore += score;
                    });
                    result.totalScore = totalScore.toFixed(1); // 保留一位小数
                    result['assessmentComments'] = assessmentRecord.assessmentComments;
                    return result;
                });
                console.log(formattedData);
                setColumnData(formattedData);

            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [id, token, selectMonth])

    return (
        <>
            <br></br>
            <Flex gap="small" wrap="wrap">
                <br />
                <DatePicker picker={'month'} defaultValue={dayjs(selectMonth, 'YYYY-MM')} format={'YYYY/MM'} onChange={setMonth} allowClear={false} />
            </Flex>
            <Table dataSource={columnData} columns={columns} rowKey={(record) => record.id} />
        </>
    )
}

export default AssessmentRecord;