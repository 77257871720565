// 下载考核表 
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Button, Flex, DatePicker, Table, Tooltip } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { summaryForm, getNotAssessBySelf, assessFrom, getAllAssessment } from '../../api/statistics/assessmentStatistics';
import dayjs from 'dayjs';
import download from 'downloadjs';
import ACTION_OF from '../../redux/action';
import store from "../../redux/store";

const AssessmentStatistics = () => {
    const { id, token } = useSelector(state => state.session);
    const [selectMonth, setSelectMonth] = useState(dayjs().date() <= 20 ? dayjs().subtract(1, 'month').format('YYYY-MM') : dayjs().format('YYYY-MM'))
    const [personList, setPersonList] = useState([]);
    const [fulfilList, setFulfilList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);

    const columns = [
        {
            title: '名字',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: '暱稱',
            dataIndex: 'nickName',
            key: 'nickName',
        }, {
            title: '部門',
            dataIndex: 'department',
            key: 'department',
        },
    ];

    const processAssessmentResults = (items) => {
        let index = 0;
        return items ? items.reduce((acc, result) => {
          index += 1;
          acc[`${index}`] = {
            score: result.assessmentItemScore,
            content: result.content,
          };
          return acc;
        }, {}) : {};
      };

      const dataSource = fulfilList.map(item => {
        let assessmentResults = processAssessmentResults(item.assessmentDetail);
    
        const assessmentResultsWithDefault = {};
        let totalScore = 0;
    
        for (let i = 1; i <= 10; i++) {
            const score = parseFloat(assessmentResults[`${i}`]?.score) || 0; // 确保分数是数字
            assessmentResultsWithDefault[`${i}`] = score;
            totalScore += score; // 数字累加
        }
    
        return {
            key: item.staffDto.id,
            staffDto: item.staffDto,
            ...assessmentResultsWithDefault,
            totalScore: totalScore.toFixed(1),
            assessmentTotalScoreBySuperior:item.assessmentTotalScoreBySuperior,
            assessmentComments:`"${item.assessmentComments}"`,
            assessmentCommentBySuperior:`"${item.assessmentCommentBySuperior}"`,
            assessmentDetail: Object.fromEntries(
                Object.entries(assessmentResults).map(([index, result]) => [index, result.content || ''])
            ),
        };
    });
    

      const assessmentColumns = Array.from({ length: 10 }, (_, index) => ({
        title: `${index + 1}`,
        dataIndex: `${index + 1}`,
        key: `assessment-${index + 1}`,
        width: 15,
        render: (text, record) => {
            const score = record[`${index + 1}`];
            const content = record.assessmentDetail[`${index + 1}`];
    
            // 同时显示分数和内容
            return (
              <Tooltip placement="topLeft" title={content}>
                {score !== undefined ? `${score}` : '-'}
              </Tooltip>
            );
        },
    }));    

    const fulfil = [
        {
            title: '名字',
            dataIndex: 'staffDto.name',
            key: 'name',
            width: 20,
            fixed: 'left',
            render: (text, record) => record.staffDto.name,
        }, {
            title: '職稱',
            dataIndex: 'staffDto.jobTitle',
            key: 'jobTitle',
            width: 20,
            render: (text, record) => record.staffDto.jobTitle,
        }, {
            title: '暱稱',
            dataIndex: 'staffDto.nickName',
            key: 'nickName',
            width: 20,
            render: (text, record) => record.staffDto.nickName,
        }, {
            title: '考核项目',
            dataIndex: 'assessmentDetail',
            key: 'assessmentDetail',
            children: assessmentColumns,
        }, {
            title: '自評總分',
            dataIndex: 'totalScore',
            key: 'totalScore',
            width: 20,
        }, {
            title: '自評',
            dataIndex: 'assessmentComments',
            key: 'assessmentComments',
            width: 30,
            ellipsis: {
                showTitle: false,
            },
            render: (address) => (
                <Tooltip placement="left" title={address}>
                  {address}
                </Tooltip>
              ),
        }, {
            title: '主管評分',
            dataIndex: 'assessmentTotalScoreBySuperior',
            key: 'assessmentTotalScoreBySuperior',
            width: 20,
            render: (address) => (
                <Tooltip placement="topLeft" title={address}>
                  {address}
                </Tooltip>
              ),
        }, {
            title: '主管評語',
            dataIndex: 'assessmentCommentBySuperior',
            key: 'assessmentCommentBySuperior',
            width: 30,
            ellipsis: {
                showTitle: false,
            },
            render: (address) => (
                <Tooltip placement="left" title={address}>
                  {address}
                </Tooltip>
              ),
        }

    ];

    const downloadsummaryForm = async () => {
        const headers = { staffId: id, token };
        const requestBody = selectMonth;
        try {
            setIsLoading2(true);
            await summaryForm(requestBody, headers).then((res) => {
                download(res.data, '考核匯整表.xlsx')
            })
                .catch(error => {
                    console.error('There has been a problem with your fetch operation:', error);
                }).finally(() => {
                    setIsLoading2(false); // 無論成功或失敗，下載結束後設置 isLoading 為 false
                });
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const downloadassessFrom = async () => {
        const headers = { staffId: id, token };
        const requestBody = selectMonth;
        try {
            setIsLoading(true);
            await assessFrom(requestBody, headers).then((res) => {
                download(res.data, '考核總表.xlsx')
            })
                .catch(error => {
                    console.error('There has been a problem with your fetch operation:', error);
                }).finally(() => {
                    setIsLoading(false); // 無論成功或失敗，下載結束後設置 isLoading 為 false
                });
        } catch (error) {
            console.error('Error:', error);
        }
    }


    const setMonth = (date) => {
        setSelectMonth(date.format('YYYY-MM'));
    };
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [response, response2] = await Promise.all([
                    getNotAssessBySelf(selectMonth, { staffId: id, token }).toPromise(),
                    getAllAssessment(selectMonth, { staffId: id, token }).toPromise()
                ]);
                setPersonList(response);
                setFulfilList(response2);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();
    }, [selectMonth]);

    const handleDoubleClick = (record) => {
        // 展开对象，确保内容是字符串
        const rowData = [
            record.staffDto.name,
            record.staffDto.jobTitle,
            record.staffDto.nickName,
            ...Array.from({ length: 10 }, (_, i) => record[`${i + 1}`]), // 展开评分 1 到 10
            record.totalScore,
            record.assessmentComments,
            record.assessmentTotalScoreBySuperior,
            record.assessmentCommentBySuperior
        ].join('\t'); // 用制表符分隔
        
        navigator.clipboard.writeText(rowData); // 复制到剪贴板
        store.dispatch(ACTION_OF.ALERT.INFO('已複製'));
    };    

    return (
        <>
            <br></br>
            <Flex gap="small" wrap="wrap">
                <br />
                <Button
                    type="primary"
                    onClick={downloadassessFrom}
                    icon={isLoading ? <LoadingOutlined /> : <DownloadOutlined />}
                >下載考核內容
                </Button>
                <Button
                    type="primary"
                    onClick={downloadsummaryForm}
                    icon={isLoading2 ? <LoadingOutlined /> : <DownloadOutlined />}
                >下載考核總匯表</Button>
                <DatePicker picker={'month'} defaultValue={dayjs(selectMonth, 'YYYY-MM')} format={'YYYY/MM'} onChange={setMonth} allowClear={false} />
            </Flex>
            <h2>已完成自評</h2>
            <div style={{ margin: '0 20px' }}>
                <Table
                    dataSource={dataSource}
                    columns={fulfil}
                    rowKey={(record) => record.staffDto.id} 
                    onRow={(record) => ({
                        onDoubleClick: () => handleDoubleClick(record),
                    })}
                /></div>
            <h2>未自評</h2>
            <div style={{ margin: '0 20px' }}>
                <Table dataSource={personList} columns={columns} rowKey={(record) => record.id} />
            </div>
        </>
    )
}

export default AssessmentStatistics;