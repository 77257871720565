//职称页面
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPermissions } from '../../api/staff';
import { Table, Form, Select, Modal, Input, Button, notification } from 'antd';
import ACTION_OF from "../../redux/action";
import store from "../../redux/store";

const JobTitle = () => {
    const { id, token } = useSelector(state => state.session);
    const [permissionsData, setPermissionsData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const { list } = useSelector(state => state.jobTitle);
    const dispatch = useDispatch();

    useEffect(() => {
        if (list.length<1) {
            dispatch(ACTION_OF.JOB_TITLE.SEARCH_JOB_TITLE.REQUEST({ staffId: id, token }));
        }
    }, [dispatch, id, token]);

    useEffect(() => {
        const fetchPermissionsData = async () => {
            try {
                const response = await getPermissions({ staffId: id, token }).toPromise();
                setPermissionsData(response);
            } catch (error) {
                store.dispatch(ACTION_OF.ALERT.ERROR(error));
            }
        }; fetchPermissionsData();
    }, [id, token]);

    const columns = [
        {
            title: '職稱',
            dataIndex: 'jobTitle',
            key: 'jobTitle',
        },
        {
            title: '權限',
            dataIndex: 'permissions',
            key: 'permissions',
            render: (text, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Select
                        defaultValue={text}
                        style={{ width: 200 }}
                        onChange={(value, option) => handleChange(value, option, record)}
                    >
                        {permissionsData.map(permission => (
                            <Select.Option key={permission.id} value={permission.permissions}>
                                {permission.permissions}
                            </Select.Option>
                        ))}
                    </Select>
                    <div></div>
                    <div>

                    </div>
                </div>
            ),
        },
        // {
        //     title: '備註',
        //     dataIndex: 'remark',
        //     key: 'remark',
        //     render: (text, record) => (
        //         <Input
        //             value={text}
        //         // onChange={(e) => handleRemarkChange(e.target.value, record)}
        //         />
        //     ),
        // },
    ];
    const handleChange = (value, option, record) => {
        console.log('Selected Value:', value);
        console.log('Selected Option:', option);
        console.log('Whole Row Data:', record);
        const getValues = {
            id: record.id,
            jobtitle: record.jobtitle,
            permissionsId: permissionsData.find(item => item.permissions === value).id,
            remark: record.remark,
        }

        const values = { getValues, staffId: id, token };
        try {
            dispatch(ACTION_OF.JOB_TITLE.UPDATE_JOB_TITLE.REQUEST(values));
        } catch (error) {
            store.dispatch(ACTION_OF.ALERT.ERROR(error));
        }
    }
    const handleCancel = () => {
        setVisible(false);
    };
    const handleSubmit = () => {
        form.validateFields().then(() => {
            const fieldsValue = form.getFieldsValue();
            const getValues = { ...fieldsValue, permissionsId: (permissionsData.find(item => item.permissions === fieldsValue.permission).id) };
            const values = { getValues, staffId: id, token };
            dispatch(ACTION_OF.JOB_TITLE.ADD_JOB_TITLE.REQUEST(values));
            notification.success({
                message: '職稱已新增成功',
                description: "麻煩請到 /編輯/考核 裡新增〝能力〞和〝態度〞考核",
            });
        }).catch(errorInfo => {
            console.error('表单校验失败:', errorInfo);
        });
        setVisible(false);
    };
    const handleAdd = () => {
        setVisible(true)
    }
    return (
        <>
            <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                <Button type="primary" onClick={handleAdd}>新增</Button>
            </div>
            <Table dataSource={list} columns={columns} rowKey={(record) => record.id} />
            <Modal
                title="新增職稱"
                open={visible}
                onOk={() => handleSubmit(form.getFieldsValue())}
                onCancel={handleCancel}
            >
                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                >
                    <Form.Item
                        label="職稱"
                        name="jobTitle"
                        rules={[{ required: true, message: '請輸入職稱!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="權限"
                        name="permission"
                        rules={[{ required: true, message: '請選擇權限!' }]}
                    >
                        <Select>
                            {permissionsData.map(permission => (
                                <Select.Option key={permission.id} value={permission.permissions}>
                                    {permission.permissions}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                </Form>
            </Modal>
        </>
    )
};

export default JobTitle;
