import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import 'antd/dist/antd.css';
import * as serviceWorker from './serviceWorker';
import App from "./App";
import {Provider} from "react-redux";
import store from "./redux/store";
import 'react-toastify/dist/ReactToastify.css';
import {toast} from "react-toastify";
import {BrowserRouter} from "react-router-dom";
import {ConfigProvider} from "antd";


toast.configure({
    autoClose: 3000,
    draggable: false,
    position: 'top-center',
});


/* eslint-disable */
const validateMessages = {
    required: "${label} 為必填欄位!",
    types: {
        number: '${label} is not a validate number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
    string: {
        range: '${label} 長度有誤![${min}-${max}]',
    }
};

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <ConfigProvider form={{validateMessages}}>
                <App/>
            </ConfigProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your Product to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
