//考核全項目
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Form, Select, Modal, Input, Button } from 'antd';
import ACTION_OF from "../../../redux/action";

const AssessmentItem = () => {
    const { id, token } = useSelector(state => state.session);
    const [visible, setVisible] = useState(false);
    const [reload, setReload] = useState(false);
    const [form] = Form.useForm();
    const { list } = useSelector(state => state.assessment);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ACTION_OF.ASSESSMENT.SEARCH_ASSESSMENT.REQUEST({ staffId: id, token }));
    }, [dispatch, id, token]);

    const columns = [
        {
            title: '項目',
            dataIndex: 'content',
            key: 'content',
        },
        {
            title: '考核類別',
            dataIndex: 'assessmentType',
            key: 'assessmentType',
        },

    ];
    const handleAdd = () => {
        setVisible(true)
    };

    const handleCancel = () => {
        form.resetFields();
        setVisible(false)
    };

    const handleSubmit = () => {
                // 提交表单数据
                form.validateFields().then(() => {
                    const getValues = form.getFieldsValue();
                    const values = { getValues, staffId: id, token };
                    // 调用更新部门列表的 Epic
                    dispatch(ACTION_OF.ASSESSMENT.UPDATE_ASSESSMENT.REQUEST(values));
                }).catch(errorInfo => {
                    // 表单校验失败，可以在这里处理错误信息
                    console.error('表单校验失败:', errorInfo);
                });
                setVisible(false);
                setReload(!reload);
    };
    return (
        <>
            <br />
            <Button type="primary" onClick={handleAdd}>新增</Button>
            <br />
            <br />
            <Table dataSource={list} columns={columns} rowKey={(record) => record.id} />
            <Modal
                title="新增考核項目"
                open={visible}
                onOk={() => handleSubmit()}
                onCancel={handleCancel}
            >
                <Form
                    form={form}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                >
                    <Form.Item
                        label="考核項目"
                        name="content"
                        rules={[{ required: true, message: '請輸入考核項目' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="考核類別"
                        name="assessmentType"
                        rules={[{ required: true, message: '請輸入考核類別' }]}
                    >
                        <Select
                            options={[
                                { value: '工作能力', label: '工作能力' },
                                { value: '工作態度', label: '工作態度' },
                            ]}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
export default AssessmentItem;