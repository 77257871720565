import {combineEpics} from 'redux-observable';
import session from "./session";
import department from "./department";
import staff from "./staff";
import assessment from "./assessment";
import jobTitle from "./jobtitle";
import teatime from "./teatime";
import assess from "./assess";

const rootEpic = combineEpics(
    ...Object.values(session),
    ...Object.values(department),
    ...Object.values(staff),
    ...Object.values(assessment),
    ...Object.values(jobTitle),
    ...Object.values(teatime),
    ...Object.values(assess),
);

export default rootEpic;