import {pipe} from "ramda";
import {ofType} from "redux-observable";
import TYPE_OF from "../action/types";
import {pluck, switchMap,mergeMap} from 'rxjs/operators';
import ACTION_OF from "../action";
import {of} from "rxjs";
import catchApiError from "../../http/catchApiError";
import {getAllJobTitle,updateJobTitle,addJobTitle} from "../../api/jobTitle";


const searchJobTitleEpic = pipe(
    ofType(TYPE_OF.JOB_TITLE.SEARCH_JOB_TITLE.REQUEST),
    pluck('payload'),
    switchMap(payload => {
        console.log(payload);
        return getAllJobTitle(payload).pipe(
            switchMap((response) => of(ACTION_OF.JOB_TITLE.SEARCH_JOB_TITLE.SUCCESS({list : response}))),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR(message)))
        )
    }),
);

const updateJobTitleEpic = pipe(
    ofType(TYPE_OF.JOB_TITLE.UPDATE_JOB_TITLE.REQUEST),
    pluck('payload'),
    mergeMap(payload => {
        const { getValues, staffId, token } = payload; 
        return updateJobTitle(getValues, { "staffId":staffId, token }).pipe( 
            mergeMap(() => {
                return of(
                    ACTION_OF.JOB_TITLE.SEARCH_JOB_TITLE.REQUEST( { "staffId":staffId, token })
                );
            }),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR( message)))
        )
    }),
);

const addJobTitleEpic = pipe(
    ofType(TYPE_OF.JOB_TITLE.ADD_JOB_TITLE.REQUEST),
    pluck('payload'),
    mergeMap(payload => {
        const { getValues, staffId, token } = payload; 
        return addJobTitle(getValues, { "staffId":staffId, token }).pipe( 
            mergeMap(() => {
                return of(
                    ACTION_OF.JOB_TITLE.SEARCH_JOB_TITLE.REQUEST( { "staffId":staffId, token })
                );
            }),
            catchApiError(message => of(ACTION_OF.ALERT.ERROR( message)))
        )
    }),
);


export default {
    searchJobTitleEpic,
    updateJobTitleEpic,
    addJobTitleEpic
};
