import Post from "../../http/Post";

export const getShop =  headers => Post(`/demo/administrative/getShop`,{},headers);

export const addShop =  (requestBody, headers) => Post(`/demo/administrative/addShop`,requestBody,headers);

export const createTeatime =  (requestBody, headers) => Post(`/demo/administrative/createTeatimeForm`,requestBody,headers);

export const getAllTeatimeMenu =  headers => Post(`/demo/administrative/getAllTeatimeMenu`,{},headers);

export const updateShopMenu =  (requestBody, headers) => Post(`/demo/administrative/updateShopMenu`,requestBody,headers);

export const deleteTeatimeForm =  (requestBody, headers) => Post(`/demo/administrative/deleteTeatimeForm`,requestBody,headers);

export const getTeatimeFormInfos =  headers => Post(`/demo/administrative/getTeatimeFormInfos`,{},headers);