import Post from "../../http/Post";
import Excel from "../../http/Excel";

export const getTeatimeOrderStatistics = headers => Post('/demo/administrative/getTeatimeOrderStatistics', {}, headers);

export const getTeatimeOrderTotalPrice = headers => Post('/demo/administrative/getTeatimeOrderTotalPrice', {}, headers);

export const getFoodTeatimeStatistics = headers => Post('/demo/administrative/getFoodTeatimeStatistics', {}, headers);

export const getSideDishTeatimeStatistics = headers => Post('/demo/administrative/getSideDishTeatimeStatistics', {}, headers);

export const getDrinkTeatimeStatistics = headers => Post('/demo/administrative/getDrinkTeatimeStatistics', {}, headers);

export const getDrinkStatistics = headers => Post('/demo/administrative/getDrinkStatistics', {}, headers);

export const teatimeStatisticsForm = headers => Excel('/demo/form/teatimeStatisticsForm',{}, headers);


