import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PermissionRoute = ({ allowedPermissions, component: Component, ...rest }) => {
    const { permissions } = useSelector(state => state.session);

    const hasPermission = useMemo(() => {
        return allowedPermissions.includes(permissions);
    }, [allowedPermissions, permissions]);

    return hasPermission ? <Component {...rest} /> : <Redirect to="/" />;
};

export default PermissionRoute;
