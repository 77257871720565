import { HomeOutlined, UserOutlined, GoldOutlined, FileOutlined, SisternodeOutlined, ProjectOutlined, ProfileOutlined, CoffeeOutlined, FileDoneOutlined, RobotOutlined, FireOutlined, ExperimentOutlined, FileTextOutlined } from '@ant-design/icons';
import React from "react";

const menuItems = (permissions) => {
    const commonItems = [
        {
            label: '首頁',
            key: '/',
            icon: <HomeOutlined />,          
        },
        {
            label: '工作考核',
            key: 'abilityAssessment',
            icon: <ProjectOutlined />,
        }
    ];

    const userItems = permissions !== 4 ? [
        {
            label: '許願池',
            key: 'wishingWell',
            icon: <FireOutlined />,
        },
        {
            label: '下午茶',
            key: 'teatimeMenu',
            icon: <CoffeeOutlined />,
        },
        {
            label: '考核紀錄',
            key: 'assessmentRecord',
            icon: <ProfileOutlined />,
        }
    ] : [];

    const adminItems = permissions === 3 ? [
        {
            label: '人事考核',
            key: 'attitudeAssessment',
            icon: <ProfileOutlined />,
        },
        {
            label: '統計表',
            key: 'statistics',
            icon: <FileDoneOutlined />,
            children: [
                {
                    label: '許願池統計表',
                    key: 'wishingWellStatistics',
                    icon: <RobotOutlined />,
                },
                {
                    label: '下午茶統計表',
                    key: 'teatimeStatistics',
                    icon: <CoffeeOutlined />,
                },
                {
                    label: '考核統計表',
                    key: 'assessmentStatistics',
                    icon: <FileTextOutlined />,
                }
            ]
        },
        {
            label: '編輯',
            key: 'edit',
            icon: <ExperimentOutlined />,
            children: [
                {
                    label: '員工',
                    key: 'staff',
                    icon: <UserOutlined />,
                },
                {
                    label: '部門',
                    key: 'department',
                    icon: <GoldOutlined />,
                },
                {
                    label: '職稱',
                    key: 'jobtitle',
                    icon: <SisternodeOutlined />,
                },
                {
                    label: '考核',
                    key: 'assessment',
                    icon: <FileOutlined />,
                    children: [
                        {
                            label: '工作考核内容',
                            key: 'abilityAssessmentForm',
                            icon: <ProjectOutlined />,
                        },
                        {
                            label: '人事考核内容',
                            key: 'attitudeAssessmentForm',
                            icon: <ProfileOutlined />,
                        },
                        {
                            label: '考核全項目',
                            key: 'assessmentItem',
                            icon: <ProfileOutlined />,
                        }
                    ]
                },
            ]
        }
    ] : [];

    const extraItems = permissions === 2 ? [
        {
            label: '下午茶統計表',
            key: 'teatimeStatistics',
            icon: <CoffeeOutlined />,
        }
    ] : [];

    const commonAdminItems = (permissions === 3 || permissions === 2) ? [
        {
            label: '下午茶菜單',
            key: 'teatimeEdit',
            icon: <CoffeeOutlined />,
        }
    ] : [];

    return [
        ...commonItems,
        ...userItems,
        ...extraItems,
        ...commonAdminItems,
        ...adminItems,
    ];
};

export default menuItems;