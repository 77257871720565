import {handleActions} from 'redux-actions';
import TYPE_OF from "../action/types";
import produce from 'immer';

const initialState = {
    isLogin: false,
    id: "",
    nickName: "",
    permissions: "",
    avatar:"",
    token: null,
};



const session = handleActions({
    [TYPE_OF.SESSION.LOGIN.SUCCESS]: produce((draft, {payload}) => {
        draft.isLogin = true;
        draft.id = payload.id;
        draft.nickName = payload.nickName;
        draft.permissions = payload.permissions;
        draft.avatar = payload.avatar;
        draft.token = payload.token;        
        window.localStorage.setItem("TOKEN", payload.token);
    }),
    [TYPE_OF.SESSION.LOGIN.FAILURE]: produce((draft) => {
        draft.isLogin = false;
        draft.id = "";
        draft.nickName = "";
        draft.permissions = "";
        draft.token = null;
        // draft.errorMessage = payload.errorMessage;    
        window.localStorage.removeItem("TOKEN");
    }),
    [TYPE_OF.SESSION.INVALID]: produce((draft) => {
        draft.isLogin = false;
        draft.id = "";
        draft.nickName = "";
        draft.permissions = "";
        draft.token = null;  
        window.localStorage.removeItem("TOKEN");
    }),
    [TYPE_OF.SESSION.LOGOUT.SUCCESS]: produce(draft => {
        draft.isLogin = false;
        draft.id = "";
        draft.nickName = "";
        draft.permissions = "";
        draft.token = null;  
        window.localStorage.removeItem("TOKEN");
    }),
}, initialState);

export default session;