import Post from "../http/Post"

export const login = requestBody => Post('/demo/login', requestBody);

export const loginByToken = headers => Post('/demo/loginByToken',{}, headers);

export const logout =  headers => Post(`/demo/logout`,{},headers);

export const hadWished =  headers => Post(`/demo/staffAction/hadWished`,{},headers);

export const makeWish =   (requestBody, headers) => Post(`/demo/staffAction/makeWish`,requestBody,headers);